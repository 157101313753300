import axios from 'axios';
import AuthService from '../auth/AuthService';
import originHeaders from '../auth/AuthService';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const get = (page, page_size) => {
    return axios.get(API_URL + 'publications?page=' + page + '&page_size=' + page_size, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

const getOne = (id) => {
    return axios.get(API_URL + 'publications/' + id, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

const update = (data) => {
    return axios.post(API_URL + 'publications/' + data.id, data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const create = (data) => {
    return axios.post(API_URL + 'publications', data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const del = (id) => {
    return axios.delete(API_URL + 'publications/' + id,
        AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const enqueue = (id, publication_date) => {
    const data = { publication_date: publication_date };
    return axios.post(API_URL + 'publications/' + id + "/enqueue", data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const publish = (id) => {
    return axios.post(API_URL + 'publications/' + id + "/enqueue", {}, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const PostsService = {
    get,
    getOne,
    update,
    del,
    create,
    enqueue,
    publish
}

export default PostsService;