import MainTitle from "./common/MainTitle";

function Home() {
    return <div>
        <MainTitle title="Главная" />
        <section>
            <div className="container-fluid">
                <p className="pt-4 pb-4">Здесь могут быть виджеты с аналитикой, последними событиями, сообщениями, ответами, транзакциями.</p>
            </div>
        </section>
    </div>
}

export default Home;