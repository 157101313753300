import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { format, parseISO } from 'date-fns'
import { useNavigate, useParams } from "react-router-dom";

import infopageStrings from './InfopageStrings';
import FileUploader from '../common/FileUploader';
import ImagesService from '../common/ImagesService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import infopage_thumb from './infopage_thumb.jpg';
import imagenotfound from './../common/imgnotfound.jpg';

import InfopagesService from './InfopagesService';

import Helper from '../common/Helper';
import Strings from '../common/Strings';

import ImagesDialog from '../common/ImagesDialog';

const IMAGE_URL = process.env.REACT_APP_API_BASE_STATIC_URL + "images/";

const InfopageEditor = ({ onUpdated, data, infopages, statuses }) => {

    const MAX_IMAGES = 10;

    const [successMessage, setSuccessMessage] = useState('');
    const [message, setMessage] = useState('');
    const [images, setImages] = useState('');
    const [showImagesDialog, setShowImagesDialog] = useState(false);
    const navigate = useNavigate();

    // const statusOptions = !statuses ? "" : statuses.map((st) =>
    //     <option key={"stat_" + st} value={st}>{infopageStrings['status'][st]}</option>
    // );

    // {
    //     "id": "1",
    //     "menu_title": "page1",
    //     "text": "This is the index page with tons of data.",
    //     "parent_id": "5",
    //     "position": 1,
    //     "images": [
    //         "1.jpg"
    //     ],
    //     "video": [
    //         465468
    //     ],
    //     "file": "test.dox",
    //     "questionnaire": "add-file",
    //     "command": "/add",
    //     "webapp_url": "page1",
    //     "previous_id": null,
    //     "webapp_callback_questionnaire": "questionnaire"
    // },

    const validationSchema = yup.object().shape({
        menu_title: yup.string()
            .required(Strings.validation.required),
        text: yup.string(),
        parent_id: yup.string().nullable(true),
        previous_id: yup.string().nullable(true),
        position: yup.number(),
        file: yup.string().nullable(true),
        questionnaire: yup.string().nullable(true),
        command: yup.string().nullable(true),
        webapp_url: yup.string().nullable(true),
        webapp_callback_questionnaire: yup.string().nullable(true),
        enabled: yup.bool(),
        // status: yup.string(""),
    });

    const { register, handleSubmit, setValue, reset, watch, control, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            position: 0,
            enabled: true,
            parent_id: "",
            previous_id: "",
            questionnaire: "",
            command: "",
            webapp_url: "",
            webapp_callback_questionnaire: ""
        }
    });

    const watchText = watch("text");

    useEffect(() => {
        if (data) {
            Object.entries(data).forEach((dataItem) => {
                if (dataItem[1] && Array.isArray(dataItem[1]) && dataItem[1].length > 0) {
                    setValue(dataItem[0], dataItem[1].map((x) => x.toString()));
                } else {
                    setValue(dataItem[0], dataItem[1]);
                }
            });
            if (data.images) {
                setImages(data.images);
            }
        }
    }, [data]);

    const handleUpdate = (formData) => {
        setMessage("");
        setSuccessMessage("");

        formData.parent_id = formData.parent_id ? parseInt(formData.parent_id) : null;
        formData.previous_id = formData.previous_id ? parseInt(formData.previous_id) : null;

        const func = data.id ? InfopagesService.update : InfopagesService.create;
        func(formData).then((response) => {
            setSuccessMessage("Инфостраница успешно " + (data.id ? "обновлена" : "создана"));
            setInterval(() => {
                setSuccessMessage("");
            }, 5000);
            onUpdated();
            if (!data) {
                reset();
                navigate("/infopages/");
            }
        },
            (error) => {
                setMessage(Helper.errorToString(error));
            });
    };

    const deleteImage = (e, image) => {

        let imgs = images.filter((x) => x != image);
        setValue("images", imgs);
        setImages(imgs);

    }

    const uploadImage = (formData, filename) => {
        return ImagesService.uploadImage(formData).then((response) => {
            const imgs = [...images, filename];
            setValue("images", imgs);
            setImages(imgs);
        });
    }

    const onSelectImage = (filename) => {
        setShowImagesDialog(false);
        const imgs = [...images, filename];
        setValue("images", imgs);
        setImages(imgs);
    }

    const imagesRender = images ? images.map((image, idx) => {
        const imgsrc = IMAGE_URL + image;
        return <div key={image + idx} className='post-image image-item'>
            <img src={imgsrc} alt="" onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = imagenotfound;
                return false;
            }} />
            <div className='tools'>
                <button onClick={(e) => deleteImage(e, image)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
            </div>
        </div>
    }) : "";

    const pageOptionsRender = !infopages ? "" : infopages.map((page) =>
        <option key={"ip_" + page.id} value={page.id}>{page.id}: {page.menu_title}</option>
    );


    // {
    //     "id": "1",
    //     "menu_title": "page1",+
    //     "text": "This is the index page with tons of data.",+
    //     "parent_id": "5",+
    //     "position": 1,+
    //     "images": [+
    //         "1.jpg"
    //     ],
    //     "video": [+
    //         465468
    //     ],
    //     "file": "test.dox",+
    //     "questionnaire": "add-file",+
    //     "command": "/add",+
    //     "webapp_url": "page1",+
    //     "previous_id": null,+
    //     "webapp_callback_questionnaire": "questionnaire"+
    // },
    return (
        <div className="">
            {data.id && <ul className="no-bullets">
                <li>id: {data.id}</li>
                {/* <li>Дата создания: {format(parseISO(data.creation_date), "dd.MM.yyyy HH:mm")}</li> */}
            </ul>}
            <div className='infopage-images-editor images-editor'>
                {imagesRender}
            </div>
            {images.length < MAX_IMAGES && <div className="form-group d-flex align-items-center">
                <label className='me-2'>Загрузить изображение</label>
                <FileUploader uploadFunc={uploadImage} />
                {/* <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasImagesDialog" aria-controls="offcanvasRight">Выбрать из загруженных ранее</button> */}
                <button className='btn btn-primary'
                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowImagesDialog(!showImagesDialog) }}>
                    Выбрать из загруженных ранее
                </button>
            </div>}
            <form onSubmit={handleSubmit(handleUpdate)} autoComplete="off">
                <div className="form-group">
                    <label>Текст инфостраницы ({watchText ? watchText.length : 0})</label>
                    <textarea rows="10" role="presentation"
                        {...register('text')}
                        className={`form-control ${errors.text ? 'is-invalid' : ''}`}
                    ></textarea>
                    <div className="form-error">{errors.text?.message}</div>
                </div>
                <div className="form-group">
                    <label>Пункт меню</label>
                    <input type="text"
                        {...register('menu_title')}
                        className={`form-control ${errors.menu_title ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.menu_title?.message}</div>
                </div>
                <div className="form-group">
                    <label>Позиция кнопки в ряду</label>
                    <input type="number"
                        {...register('position')}
                        className={`form-control ${errors.position ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.position?.message}</div>
                </div>
                <div className="form-group">
                    <label>Родительская страница</label>
                    <select
                        {...register('parent_id')}
                        className="form-control"
                    >
                        <option value="">Нет</option>
                        {pageOptionsRender}
                    </select>
                </div>
                <div className="form-group">
                    <label>Предыдущая страница</label>
                    <select
                        {...register('previous_id')}
                        className="form-control"
                    >
                        <option value="">Нет</option>
                        {pageOptionsRender}
                    </select>
                </div>
                <div className="form-group">
                    <label>ID видео</label>
                    <input role="presentation"
                        name="video"
                        type="text"
                        {...register('video')}
                        className={`form-control ${errors.video ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.video?.message}</div>
                </div>
                <div className="form-group">
                    <label>ID файла</label>
                    <input role="presentation"
                        name="file"
                        type="text"
                        {...register('file')}
                        className={`form-control ${errors.file ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.file?.message}</div>
                </div>
                <div className="form-group">
                    <label>ID опросника</label>
                    <input role="presentation"
                        name="questionnaire"
                        type="text"
                        {...register('questionnaire')}
                        className={`form-control ${errors.questionnaire ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.questionnaire?.message}</div>
                </div>
                <div className="form-group">
                    <label>Команду бота по клику на кнопку</label>
                    <input role="presentation"
                        name="command"
                        type="text"
                        {...register('command')}
                        className={`form-control ${errors.command ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.command?.message}</div>
                </div>
                <div className="form-group d-none">
                    <label>webapp_url</label>
                    <input role="presentation"
                        name="webapp_url"
                        type="text"
                        {...register('webapp_url')}
                        className={`form-control ${errors.webapp_url ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.webapp_url?.message}</div>
                </div>
                <div className="form-group d-none">
                    <label>webapp_callback_questionnaire</label>
                    <input role="presentation"
                        name="webapp_callback_questionnaire"
                        type="text"
                        {...register('webapp_callback_questionnaire')}
                        className={`form-control ${errors.webapp_callback_questionnaire ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.webapp_callback_questionnaire?.message}</div>
                </div>
                <div className="form-check d-none">
                    <input className="form-check-input "
                        disabled="disabled"
                        id="enabledCheck"
                        name="enabled"
                        type="checkbox"
                        {...register('enabled')}
                    />
                    <label htmlFor="enabledCheck">Включен</label>
                </div>
                <div className="mt-2 d-flex align-items-center justify-content-between">
                    <button type="submit" className="btn btn-primary">
                        {data.id ? "Обновить" : "Создать"}
                    </button>
                </div>
            </form>
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}

            {showImagesDialog && <ImagesDialog show={true} onSelectImage={onSelectImage} id="offcanvasImagesDialog" onClose={() => setShowImagesDialog(false)} />}
        </div>
    );
}

export default InfopageEditor