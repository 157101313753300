import axios from 'axios';
import AuthService from '../auth/AuthService';
import originHeaders from '../auth/AuthService';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const get = () => {
    return axios.get(API_URL + 'images', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

// const getOne = (id) => {
//     return axios.get(API_URL + 'images/'+id, AuthService.getTokenHeaders())
//         .then(function (response) {
//             return response.data;
//         })
// }

// const update = (data) => {
//     return axios.post(API_URL + 'images', data, AuthService.getTokenHeaders())
//         .then((response) => {
//             return response.data;
//         });
// };

const del = (filename) => {
    return axios.delete(API_URL + 'images/' + filename,
        AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const uploadImage = (formData, onUploadProgress) => {
    let headers = AuthService.getTokenHeaders();
    headers.onUploadProgress = onUploadProgress;
    //console.log(formData);
    return axios.put(API_URL + 'images', formData, headers)
        .then(response => {
            return response.data;
        });
}

const importImagesArchive = (formData, onUploadProgress) => {
    let headers = AuthService.getTokenHeaders();
    headers.onUploadProgress = onUploadProgress;
    //console.log(formData);
    return axios.put(API_URL + 'images/archive', formData, headers).then(response => {
        return response.data;
    });
}

const ImagesService = {
    get,
    uploadImage,
    importImagesArchive,
    del
}

export default ImagesService;