import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { format, parseISO } from 'date-fns'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SettingsForm = ({ title, questions, handleFormSubmit }) => {

    const [message, setMessage] = useState();
    //const [answers, setAnswers] = useState();

    const answers = questions.reduce((answersObject, question, idx) => {
        const fieldName = question.id;
        answersObject[fieldName] = question.value;
        return answersObject;
    }, {});

    const validationSchemaShape = questions.reduce((validationSchemaShape, question, idx) => {
        const fieldName = question.id;

        switch (question.type) {
            case "date":
            case "number":
            case "string":
            case "text":
                if (question.hasOwnProperty("regex")) {
                    validationSchemaShape[fieldName] = yup.string()
                        .trim()
                        .matches(question.regex, 'Некорректный формат')
                        .required("Поле обязательно для заполнения");
                } else {
                    validationSchemaShape[fieldName] = yup.string().trim().required("Поле обязательно для заполнения");
                }
                break;
            case "enum":
                validationSchemaShape[fieldName] = yup.string();
            case "bool":
                validationSchemaShape[fieldName] = yup.boolean();
            case "int":
                validationSchemaShape[fieldName] = yup.number().integer();
            default:
                validationSchemaShape[fieldName] = yup.string().trim().required("Поле обязательно для заполнения");
                break;
        }
        return validationSchemaShape;
    }, {});

    const { register, handleSubmit, reset, control, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(yup.object().shape(validationSchemaShape))
    });

    const dataForm = !questions ? "" : questions.map((question) => {
        const fieldName = question.id;
        let inputRender = "";

        switch (question.type) {
            case "date":
                inputRender = <Controller
                    control={control}
                    name={fieldName}
                    render={({ field }) => (
                        <DatePicker
                            className="form-control"
                            dateFormat="dd.MM.yyyy"
                            placeholderText="Выберите дату"
                            onChange={(date) => field.onChange(date, setValue(fieldName, format(date, "dd.MM.yyyy")))}
                            selected={field.value && new Date(field.value)}
                        />
                    )}
                />
                break;
            case "number":
            case "int":
                inputRender = <input name={fieldName}
                    type="number"
                    autoComplete="off"
                    {...register(fieldName)}
                    className={`form-control ${errors.hasOwnProperty(fieldName) ? 'is-invalid' : ''}`}
                />
            case "string":
                inputRender = <input name={fieldName}

                    type="text"
                    autoComplete="off"
                    {...register(fieldName)}
                    className={`form-control ${errors.hasOwnProperty(fieldName) ? 'is-invalid' : ''}`}
                />
                break;
            case "text":
                inputRender = <textarea name={fieldName}

                    {...register(fieldName)}
                    className={`form-control ${errors.hasOwnProperty(fieldName) ? 'is-invalid' : ''}`}
                ></textarea>
                break;
            case "bool":
                // inputRender = <input type="checkbox" name={fieldName}
                //     {...register(fieldName)}
                //     //onChange={onChange}
                //     // className={`form-control ${errors.hasOwnProperty(fieldName) ? 'is-invalid' : ''}`}
                // />

                inputRender = <select name={fieldName}
                    {...register(fieldName)}
                    //onChange={onChange}
                    className={`form-control ${errors.hasOwnProperty(fieldName) ? 'is-invalid' : ''}`}
                >
                    <option value={true}>Да</option>
                    <option value={false}>Нет</option>
                </select>
                break;
            case "enum":
                let idx = 0;
                let selectItems = question.answers.map((answer) => {
                    idx++;
                    return <option activates_block={answer.activates_block} key={"opt_" + idx}>{answer.text}</option>
                });

                // const onChange = (e) => {
                //     const activates_blocks = [...(e.target.childNodes)].map((x) => x.getAttribute("activates_block"));
                //     const selectedIndex = e.target.selectedIndex;
                //     activates_blocks.forEach((item, idx) => {
                //         if (item && idx == selectedIndex) {
                //             activateBlock(item, true);

                //         }
                //         else if (item) {
                //             activateBlock(item, false);
                //         }
                //     });
                // };

                inputRender = <select name={fieldName}

                    {...register(fieldName)}
                    //onChange={onChange}
                    className={`form-control ${errors.hasOwnProperty(fieldName) ? 'is-invalid' : ''}`}
                >{selectItems}</select>
                break;
            default:
                inputRender = <input name={fieldName}
                    type="text"
                    autoComplete="off"
                    {...register(fieldName)}
                    className={`form-control ${errors.hasOwnProperty(fieldName) ? 'is-invalid' : ''}`}
                />
                break;
        }

        return <div key={fieldName} className="form-group">
            <label>{question.title}</label>
            {inputRender}
        </div>
    });

    useEffect(() => {
        reset(answers);
    }, []);

    return <section className="commonFormSection">

        {<h2>{title}</h2>}
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            {dataForm}

            <div className="d-flex align-items-center justify-content-between">
                <button type="submit" className="btn btn-primary">
                    Сохранить
                </button>
            </div>

        </form>
        {message && (<div className="alert alert-danger mt-4">
            {message}
        </div>)}

    </section>
}

export default SettingsForm;