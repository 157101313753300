import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { format, parseISO, addDays, addMonths, addYears } from 'date-fns';


import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import Helper from '../common/Helper';
import Strings from '../common/Strings';
import Toasts from '../common/Toasts';

import './Analytics.css';

import AnalyticsStrings from './AnalyticsStrings';
import AnalyticsService from './AnalyticsService';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ChartWidget from './ChartWidget';
import NumberWidget from './NumberWidget';
import TableWidget from './TableWidget';

const Analytics = () => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [widgets, setWidgets] = useState([]);

    const [startDate, setStartDate] = useState(format(addDays(new Date(), -7), "yyyy-MM-dd"));
    const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));

    const [dateMode, setDateMode] = useState("week");


    const [incidents, setIncidents] = useState("");
    const [activeUsersCount, setActiveUsersCount] = useState("");
    const [chatSubscribersCount, setChatSubscribersCount] = useState("");
    const [chatSubscribtion, setChatSubscribtion] = useState("");

    const [chatReactionsStat, setChatReactionsStat] = useState("");


    const [hashtagsStat, setHashtagsStat] = useState("");

    const [messagesCount, setMessagesCount] = useState("");

    const [deletedMessagesCount, setDeletedMessagesCount] = useState("");

    const [blockedUsers, setBlockedUsers] = useState("");

    const [likesForPost, setLikesForPost] = useState('');

    // lines = [{
    //     hidden: false,
    //     title: "Some name",
    //     key: "value",
    //     color: AnalyticsStrings.colors[0],
    //     dashed: false
    // }];

    const getWidgets = () => {
        setWidgets([]);
        AnalyticsService.getIncidents(new Date(startDate).toISOString(), new Date(endDate).toISOString()).then(
            (response) => {
                const title = "Удаленные сообщения (антимат)";
                const data = Object.entries(response).map((item) => { return { name: item[0], value: item[1] } });
                const lines = [{ title: title, key: "value", color: AnalyticsStrings.colors[0], dashed: false }];

                setIncidents({ title, data, lines });
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });
        AnalyticsService.getActiveUsersCount(new Date(startDate).toISOString(), new Date(endDate).toISOString()).then(
            (response) => {
                const title = "Активные пользователи";
                const keys = [];
                const data = response.map((x) => {
                    const name = x.date;
                    const dataObj = x.chats_statistic.reduce((accumulator, currentValue, idx) => {
                        const key = "value_" + idx;
                        if (!keys.find(x => x.key == key)) {
                            keys.push({ key: key, title: currentValue.group_chat_name });
                        }
                        accumulator[key] = currentValue.users_count;
                        return accumulator;
                    }, {});
                    dataObj["name"] = name;
                    return dataObj;
                });

                const lines = keys.map((key, idx) => {
                    return { title: key.title, key: key.key, color: AnalyticsStrings.colors[idx], dashed: false }
                });

                setActiveUsersCount({ title, data, lines });
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });
        AnalyticsService.getChatSubscribersCount().then(
            (response) => {
                const data = response.map((x) => ({ name: x.group_chat_name, value: x.users_count }));
                setChatSubscribersCount({ title: "Количество подписчиков в группах", data: data });
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });

        AnalyticsService.getChatSubscription(new Date(startDate).toISOString(), new Date(endDate).toISOString()).then(
            (response) => {
                const title = "Подписчики";
                const keys = [];

                const data = response.map((x) => {
                    const name = x.date;
                    const info = x.chats_statistic.map((stat) => {
                        return {
                            group_chat_name: stat.group_chat_name,
                            join: stat.chat_statistic.group_join_count,
                            left: stat.chat_statistic.group_left_count
                        }
                    });
                    const dataObj = info.reduce((accumulator, currentValue, idx) => {
                        //accumulator["name_" + idx] = currentValue.group_chat_name;
                        const key1 = "join_" + idx;
                        accumulator[key1] = currentValue.join;
                        if (!keys.find(x => x.key == key1)) {
                            keys.push({ key: key1, title: currentValue.group_chat_name + " вступили" });
                        }

                        const key2 = "left_" + idx;
                        accumulator[key2] = currentValue.left;
                        if (!keys.find(x => x.key == key2)) {
                            keys.push({ key: key2, title: currentValue.group_chat_name + " вышли", dashed: true });
                        }

                        return accumulator;
                    }, {});

                    dataObj["name"] = name;
                    return dataObj;
                });

                const lines = keys.map((key, idx) => {
                    return { title: key.title, key: key.key, color: AnalyticsStrings.colors[idx], dashed: key.dashed ? key.dashed : false }
                });

                setChatSubscribtion({ title, data, lines });
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });

        AnalyticsService.getReactionsStat(new Date(startDate).toISOString(), new Date(endDate).toISOString()).then(
            (response) => {
                const title = "Количество лайков в чатах";
                const keys = [];

                const data = response.map((x) => {
                    const name = x.date;
                    const info = x.chats_statistic.map((stat) => {
                        return {
                            group_chat_name: stat.group_chat_name,
                            like_count: stat.chat_statistic.like_count,
                            dislike_count: stat.chat_statistic.dislike_count
                        }
                    });
                    const dataObj = info.reduce((accumulator, currentValue, idx) => {
                        //accumulator["name_" + idx] = currentValue.group_chat_name;
                        const key1 = "like_count_" + idx;
                        accumulator[key1] = currentValue.like_count;
                        if (!keys.find(x => x.key == key1)) {
                            keys.push({ key: key1, title: currentValue.group_chat_name + " лайк" });
                        }

                        const key2 = "dislike_count_" + idx;
                        accumulator[key2] = currentValue.dislike_count;
                        if (!keys.find(x => x.key == key2)) {
                            keys.push({ key: key2, title: currentValue.group_chat_name + " дизлайк", dashed: true });
                        }

                        return accumulator;
                    }, {});

                    dataObj["name"] = name;
                    return dataObj;
                });

                const lines = keys.map((key, idx) => {
                    return { title: key.title, key: key.key, color: AnalyticsStrings.colors[idx], dashed: key.dashed ? key.dashed : false }
                });

                setChatReactionsStat({ title, data, lines });
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });

        AnalyticsService.getHashtagsStat(new Date(startDate).toISOString(), new Date(endDate).toISOString()).then(
            (response) => {
                const title = "Хэштеги в чатах";
                const keys = [];

                const data = response.map((x) => {
                    const name = x.date;
                    const info = x.chats_statistic.map((stat) => {
                        stat.hashtag_counts.group_chat_name = stat.group_chat_name;
                        return stat.hashtag_counts;
                    });
                    const dataObj = info.reduce((accumulator, currentValue, idx) => {
                        //accumulator["name_" + idx] = currentValue.group_chat_name;

                        Object.entries(currentValue).forEach(element => {
                            if (element[0] != "group_chat_name") {
                                const key1 = element[0] + "_" + idx;
                                accumulator[key1] = element[1];
                                if (!keys.find(x => x.key == key1)) {
                                    keys.push({ key: key1, title: currentValue.group_chat_name + " " + element[0] });
                                }
                            }
                        });



                        return accumulator;
                    }, {});

                    dataObj["name"] = name;
                    return dataObj;
                });

                const lines = keys.map((key, idx) => {
                    return { title: key.title, key: key.key, color: AnalyticsStrings.colors[idx], dashed: key.dashed ? key.dashed : false }
                });

                setHashtagsStat({ title, data, lines });
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });

        AnalyticsService.getMessagesCountStat(new Date(startDate).toISOString(), new Date(endDate).toISOString()).then(
            (response) => {
                const title = "Количество сообщений в чатах";
                const keys = [];
                const data = response.map((x) => {
                    const name = x.date;
                    const dataObj = x.chats_statistic.reduce((accumulator, currentValue, idx) => {
                        const key = "value_" + idx;
                        if (!keys.find(x => x.key == key)) {
                            keys.push({ key: key, title: currentValue.group_chat_name });
                        }
                        accumulator[key] = currentValue.messages_count;
                        return accumulator;
                    }, {});
                    dataObj["name"] = name;
                    return dataObj;
                });

                const lines = keys.map((key, idx) => {
                    return { title: key.title, key: key.key, color: AnalyticsStrings.colors[idx], dashed: false }
                });

                setMessagesCount({ title, data, lines });
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });

        AnalyticsService.getChatTop5Stat().then(
            (response) => {
                console.log("TOP5", response);
                //const data = response.map((x) => ({ name: x.group_chat_name, value: x.users_count }));
                //setChatSubscribersCount({ title: "Количество подписчиков в группах", values: data });
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });

        AnalyticsService.getChatIncidentsStat(new Date(startDate).toISOString(), new Date(endDate).toISOString()).then(
            (response) => {
                const title = "Количество удаленных сообщений в чатах";
                const keys = [];
                const data = response.map((x) => {
                    const name = x.date;
                    const dataObj = x.chats_statistic.reduce((accumulator, currentValue, idx) => {
                        const key = "value_" + idx;
                        if (!keys.find(x => x.key == key)) {
                            keys.push({ key: key, title: currentValue.group_chat_name });
                        }
                        accumulator[key] = currentValue.messages_count;
                        return accumulator;
                    }, {});
                    dataObj["name"] = name;
                    return dataObj;
                });

                const lines = keys.map((key, idx) => {
                    return { title: key.title, key: key.key, color: AnalyticsStrings.colors[idx], dashed: false }
                });

                setDeletedMessagesCount({ title, data, lines });
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });
        AnalyticsService.getBlockedUsersStat(new Date(startDate).toISOString(), new Date(endDate).toISOString()).then(
            (response) => {
                const title = "Количество заблокированных пользователей в чатах";
                const keys = [];
                const data = response.map((x) => {
                    const name = x.date;
                    const dataObj = x.chats_statistic.reduce((accumulator, currentValue, idx) => {
                        const key = "value_" + idx;
                        if (!keys.find(x => x.key == key)) {
                            keys.push({ key: key, title: currentValue.group_chat_name });
                        }
                        accumulator[key] = currentValue.deleted_users_count;
                        return accumulator;
                    }, {});
                    dataObj["name"] = name;
                    return dataObj;
                });

                const lines = keys.map((key, idx) => {
                    return { title: key.title, key: key.key, color: AnalyticsStrings.colors[idx], dashed: false }
                });

                setBlockedUsers({ title, data, lines });
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });

        AnalyticsService.getLikesForPostsStat(new Date(startDate).toISOString(), new Date(endDate).toISOString()).then(
            (response) => {
                const data = response.map((x) => ({ creation_date: x.creation_date, name: x.message_text, value: x.likes_counter }));
                setLikesForPost({ title: "Количество лайков за пост", data: data });
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });
    }

    const changePeriod = (mode, startDate, endDate) => {
        setDateMode(mode);
        switch (mode) {
            case "week":
                setEndDate(format(new Date(), "yyyy-MM-dd"));
                setStartDate(format(addDays(new Date(), -7), "yyyy-MM-dd"));
                break;
            case "month":
                setEndDate(format(new Date(), "yyyy-MM-dd"));
                setStartDate(format(addMonths(new Date(), -1), "yyyy-MM-dd"));
                break;
            case "year":
                setEndDate(format(new Date(), "yyyy-MM-dd"));
                setStartDate(format(addYears(new Date(), -1), "yyyy-MM-dd"));
                break;
            case "quarter":
                setEndDate(format(new Date(), "yyyy-MM-dd"));
                setStartDate(format(addMonths(new Date(), -3), "yyyy-MM-dd"));
                break;
            case "manual":

                break;
            default:
                setDateMode("week");
                setEndDate(format(new Date(), "yyyy-MM-dd"));
                setStartDate(format(addDays(new Date(), -7), "yyyy-MM-dd"));
                break;
        }

    }

    useEffect(() => {
        getWidgets();
    }, [startDate, endDate]);

    const incidentsRender = !incidents ? "" : <ChartWidget {...incidents} />;
    const activeUsersCountRender = !activeUsersCount ? "" : <ChartWidget {...activeUsersCount} />
    const chatSubscribersCountRender = !chatSubscribersCount ? "" : <TableWidget {...chatSubscribersCount} />
    const chatSubscribtionRender = !chatSubscribtion ? "" : <ChartWidget {...chatSubscribtion} />;
    const chatReactionsStatRender = !chatReactionsStat ? "" : <ChartWidget {...chatReactionsStat} />;
    const hashtagsStatRender = !hashtagsStat ? "" : <ChartWidget {...hashtagsStat} />;
    const messagesCountRender = !messagesCount ? "" : <ChartWidget {...messagesCount} />;
    const deletedMessagesCountRender = !deletedMessagesCount ? "" : <ChartWidget {...deletedMessagesCount} />;
    const blockedUsersRender = !blockedUsers ? "" : <ChartWidget {...blockedUsers} />;
    const likesForPostRender = !likesForPost ? "" : <TableWidget {...likesForPost} />

    return (<div>
        <div className="container-fluid">
            <MainTitle title="Публикации" />
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}
            <div className="control-panel">
                <div className="btn-group">
                    <a href="#" onClick={(e) => changePeriod("week")} className={"btn " + (dateMode == "week" ? "btn-primary active" : "btn-outline-primary")}>Неделя</a>
                    <a href="#" onClick={(e) => changePeriod("month")} className={"btn " + (dateMode == "month" ? "btn-primary active" : "btn-outline-primary")}>Месяц</a>
                    <a href="#" onClick={(e) => changePeriod("quarter")} className={"btn " + (dateMode == "quarter" ? "btn-primary active" : "btn-outline-primary")}>Квартал</a>
                    <a href="#" onClick={(e) => changePeriod("year")} className={"btn " + (dateMode == "year" ? "btn-primary active" : "btn-outline-primary")}>Год</a>
                    <a href="#" onClick={(e) => changePeriod("manual")} className={"btn " + (dateMode == "manual" ? "btn-primary active" : "btn-outline-primary")}>Задать вручную</a>
                </div>
                {dateMode == "manual" && <Fragment>
                    <input type="date" className='form-control w100 ms-2' value={startDate} name="startDate" onChange={(e) => setStartDate(e.target.value)} />
                    <input type="date" className='form-control w100 ms-2' value={endDate} name="endDate" onChange={(e) => setEndDate(e.target.value)} />
                </Fragment>}
            </div>
            <div className='row'>
                <div className='col-md-6 mb-4'>
                    {incidentsRender}
                </div>
                <div className='col-md-6 mb-4'>
                    {activeUsersCountRender}
                </div>
                <div className='col-md-6 mb-4'>
                    {chatSubscribersCountRender}
                </div>
                <div className='col-md-6 mb-4'>
                    {chatSubscribtionRender}
                </div>
                <div className='col-md-6 mb-4'>
                    {chatReactionsStatRender}
                </div>
                <div className='col-md-6 mb-4'>
                    {hashtagsStatRender}
                </div>
                <div className='col-md-6 mb-4'>
                    {messagesCountRender}
                </div>
                <div className='col-md-6 mb-4'>
                    {deletedMessagesCountRender}
                </div>
                <div className='col-md-6 mb-4'>
                    {blockedUsersRender}
                </div>
                <div className='col-md-6 mb-4'>
                    {likesForPostRender}
                </div>
            </div>
            <Toasts message={message} setMessage={setMessage} />
        </div>

    </div>)
}

export default Analytics