

function UnderConstruction() {
    return (
        <section>
            <div className='container-fluid'>
                <h2>Страница в разработке</h2>
                <p>Информация скоро появится...</p>
            </div>
        </section>
    );
}

export default UnderConstruction;