import React, { useState, useEffect } from 'react';
import SettingsService from './SettingsService';
import Helper from '../common/Helper';
import MainTitle from '../common/MainTitle';
import settingsStrings from './SettingsStrings';
import CommonForm from '../common/CommonForm';
import SettingsForm from './SettingsForm';
import Toasts from '../common/Toasts';

const Settings = () => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [settings, setSettings] = useState('');
    const [selectedTab, setSelectedTab] = useState('');

    const getSettings = () => {
        SettingsService.get().then((responce) => {
            setSettings(responce);
            console.log(responce);
        }, (error) => {
            setMessage(Helper.errorToString(error));
        });
    }

    useEffect(() => {
        getSettings();
    }, []);

    const navTabs = !settings ? "" : settings.map((sets, idx) => {
        const settingsObject = Object.entries(sets)[0];
        const tabPaneId = "tab-pane-" + settingsObject[0];

        const isSelected = selectedTab ? settingsObject[0] == selectedTab : idx == 0;

        return <li key={"tab_" + settingsObject[0]} className="nav-item" role="presentation">
            <button onClick={() => setSelectedTab(settingsObject[0])} className={isSelected ? "nav-link active" : "nav-link"} type="button" role="tab" aria-controls={tabPaneId} aria-selected={isSelected}>
                {settingsStrings.group[settingsObject[0]]}
            </button>
        </li>
    });

    const handleSettingsSubmit = (data) => {
        const settingsValues = Object.entries(data).map((obj) => { return { id: obj[0], value: obj[1] } })
        SettingsService.update(settingsValues).then((responce) => { 
            setSuccessMessage("Настройки успешно обновлены");
            setInterval(() => {
                setSuccessMessage("");
            }, 5000);
        }, (error) => {
            setMessage(Helper.errorToString(error));
        });
    }

    const navTabContents = !settings ? "" : settings.map((sets, idx) => {
        const settingsObject = Object.entries(sets)[0];
        const tabPaneId = "tab-pane-" + settingsObject[0];

        const isSelected = selectedTab ? settingsObject[0] == selectedTab : idx == 0;

        return <div key={"tabcontent_" + settingsObject[0]} className={isSelected ? "tab-pane fade show active" : "tab-pane fade"} id={tabPaneId} role="tabpanel" aria-labelledby="home-tab" tabIndex={idx}>
            <SettingsForm title="" questions={settingsObject[1]} handleFormSubmit={handleSettingsSubmit} />
            {/* <CommonForm title="Анкета пользователя" questions={settingsObject[1]} handleFormSubmit={handleSettingsSubmit} answers={settingsObject[1]} /> */}
        </div>
    });

    return (<div>
        <div className="container-fluid">
            <MainTitle title="Настройки" />
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}
            <div className='mt-4 mb-4'>
                <ul className="nav nav-tabs" id="settingsTab" role="tablist">
                    {navTabs}
                </ul>
                <div className="tab-content pb-4 pt-4" id="settingsTabContent">
                    {navTabContents}
                </div>
            </div>
            <Toasts message={message} setMessage={setMessage} />
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}
        </div>
    </div>)
}

export default Settings