import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import Helper from '../common/Helper';
import Strings from '../common/Strings';
import Toasts from '../common/Toasts';

import './Comments.css';

import CommentsStrings from './CommentsStrings';
import CommentsService from './CommentsService';
import CommentEditor from './CommentEditor';


const Comments = ({ publicationId }) => {

    const [message, setMessage] = useState('');
    const [comments, setComments] = useState('');
    const [inter, setInter] = useState('');

    const getComments = () => {
        CommentsService.get(publicationId).then(
            (response) => {
                setComments(response);
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const refresh = () => {
        getComments();
    }

    const deleteComment = (e, id) => {
        CommentsService.del(publicationId, id).then(
            (response) => {
                refresh();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    useEffect(() => {
        refresh();
    }, []);

    const commentsRender = !comments ? "" : comments.map((comment, idx) => {
        return <div className='comment' key={"comment_" + idx}>
            <div className='d-flex align-items-center'>
                <em className='fa fa-user'></em>
                <div className='comment-author'>
                    <div className='comment-author'>{comment.user.name}</div>
                    <div className='comment-date'>{format(parseISO(comment.creation_date), "dd.MM.yyyy HH:mm")}</div>
                </div>
                <div className='comment-tools ms-auto'>
                    <button onClick={(e) => deleteComment(e, comment.id)} className="btn btn-outline-secondary btn-sm"><i className="fa fa-trash-o"></i></button>
                </div>
            </div>
            <p className='comment-text'>{comment.comment_text}</p>
        </div>
    });

    return (<div className='mt-4'>
        <h2>Комментарии</h2>
        <div><CommentEditor publicationId={publicationId} onUpdated={refresh} /></div>
        <div className='mt-4'>{commentsRender}</div>
    </div>)
}

export default Comments