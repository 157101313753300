import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const register = (email, password) => {
    return axios.post(API_URL + "users/register", {
        email,
        password,
    }, originHeaders)
    .then((response) => {
        return response.data;
    });
};

const originHeaders = {
    headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_API_BASE_URL,
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        "Vary": "Origin"
    }
};

const login = (email, password) => {
    return axios
        .post(API_URL + "users/auth", {
            email,
            password,
        }, originHeaders)
        .then((response) => {
            if (response.data.access_token) {
                localStorage.setItem("user", JSON.stringify(response.data));
                console.log(response.data);
            }

            return response.data;
        });

    // const mockLogin = new Promise((resolve, reject) => {
    //     if (username == 'admin' && password == '123456') {
    //         localStorage.setItem("user", JSON.stringify({username}));
    //         resolve('Все прошло отлично!');
    //     } else {
    //         reject('Что-то пошло не так');
    //     }
    // });
    // return mockLogin;

};

const logout = () => {
    localStorage.removeItem("user");
    // return axios.post(API_URL + "signout").then((response) => {
    //     return response.data;
    // });
};

const getCurrentAuth = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const setQuestionareCompleted = (value) => {
    let auth = getCurrentAuth();
    auth.questionnaire_is_completed = value;

    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(auth));
}

const setDisabled = (value) => {
    let auth = getCurrentAuth();
    auth.disabled = value;

    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(auth));
}

const getTokenHeaders = () => {
    let token = getCurrentAuth().access_token;
    let headers = {
        headers: {
            'Authorization': `Bearer ${token}`,

            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Headers': '*',

        }
    };

    return headers;
}

const AuthService = {
    register,
    login,
    logout,
    getCurrentAuth,
    getTokenHeaders,
    setQuestionareCompleted,
    setDisabled
}

export default AuthService;