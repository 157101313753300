import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate, Navigate } from "react-router-dom";
import DisabledUser from './DisabledUser';
import AuthService from './AuthService';

const AuthRoute = ({ children, group, isQuestionnare }) => {

    let user = AuthService.getCurrentAuth();
    if (!user) {
        return <Navigate to={"/login?return_url=" + window.location.pathname + window.location.search} />;
    } else if (user.disabled) {
        return <DisabledUser />
    }
    if (user && group && !user.user_groups.map(x => x.name).includes(group)) {
        return <Navigate to="/" />;
    }

    return children;

};

export default AuthRoute