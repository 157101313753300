

const Strings = {
    errors: {
        'Incorrect username or password': 'Неправильное имя или пароль пользователя',
        'Disabled': 'Ваша учетная запись отключена.',
        'Image with this name already exists': 'Изображение с таким именем файла было загружено ранее'
        
    },
    validation: {
        'required': 'Поле обязательно для заполнения',
        'email': 'Введите правильный Email'
    },
    messages: {
        'NotImplemented': 'Функция пока не реализована, но скоро появится'
    }
}

export default Strings