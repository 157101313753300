const AnalyticsStrings = {
    period: {
        week: "Неделя",
        month: "Месяц",
        quarter: "Квартал",
        year: "Год",
        manual: "Задать вручную"
    },
    userSource: {
        direct: "по прямому переходу",
        referral: "по реферальной ссылке",
        report: "через репост"
    },
    event: {
        group_join: "присоединился к группе",
        group_left: "покинул группу",
        subscribed: "подписался",
        unsubscribed: "отписался"
    },
    colors: [
        "#ff0000",
        "#3abe00",
        "#7084e6",
        "#e67070",
        "#00ff23",
        "#001fbe",
        "#be0000",
        "#70e681",
        "#4700ff",
        "#ff6c00",
        "#00be1a",
        "#9170e6",
        "#e6a270",
        "#00ff8f",
        "#3500be",
        "#be5000",
        "#70e6b2",
        "#b400ff",
        "#ffd800",
        "#00be6b",
        "#c370e6",
        "#e6d470",
        "#00fffc",
        "#8600be",
        "#bea000",
        "#70e6e4",
        "#ff00de",
        "#baff00",
        "#00bebb",
        "#e670d6",
        "#c6e670",
        "#0096ff",
        "#be00a5",
        "#8abe00",
        "#70b5e6",
        "#ff0072",
        "#4eff00",
        "#006fbe",
        "#e670a5",
        "#94e670",
        "#002aff",
        "#be0054",
    ]
}

export default AnalyticsStrings;