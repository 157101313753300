import React, { useState, useEffect } from 'react';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import TransactionsService from './TransactionsService';
import Helper from '../common/Helper';
import TransactionsStrings from './TransactionsStrings';

const TransactionEditor = ({ userId, onUpdated }) => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const createTransaction = (data) => {
        data.user_id = userId;
        TransactionsService.create(data).then(
            (response) => {
                console.log(response);
                onUpdated();
                reset();
                setSuccessMessage(TransactionsStrings.transactionCreated);
                setInterval(() => {
                    setSuccessMessage("");
                }, 3000);
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    };
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(yup.object().shape({
            amount: yup.number().required("Поле обязательно для заполнения")
        })),
        defaultValues: {
            amount: 10,
            description: "Пополнение вручную администратором"
        }
    });
    return <div className='mt-4 mb-4 ms-3 me-3'>
        <h3>Пополнить баланс</h3>
        <form onSubmit={handleSubmit(createTransaction)}>
            <div className='form-grouop'>
                <label>Сумма в баллах</label>
                <input
                    name="amount"
                    type="number"
                    {...register('amount')}
                    className={`form-control ${errors.amount ? 'is-invalid' : ''}`}
                />
            </div>
            <div className='form-grouop'>
                <label>Описание</label>
                <textarea
                    name="description"
                    {...register('description')}
                    className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                ></textarea>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-4">
                <button type="submit" className="btn btn-primary">
                    Пополнить
                </button>
            </div>
        </form>
        {message && (<div className="alert alert-success mt-4">
            {message}
        </div>)}
        {successMessage && (<div className="alert alert-success mt-4">
            {successMessage}
        </div>)}
    </div>
}

export default TransactionEditor;