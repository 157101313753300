import React, { useState, useEffect } from 'react';
import ImagesService from './ImagesService';
import './ImagesDialog.css';

const IMAGE_URL = process.env.REACT_APP_API_BASE_STATIC_URL + "images/";

const ImagesDialog = ({ onSelectImage, id, show, onClose }) => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [images, setImages] = useState('');
    const [offcanvasObj, setOffcanvasObj] = useState('');

    const getImages = () => {
        ImagesService.get().then((response) => {
            console.log(response);
            setImages(response);
        });
    }

    const imagesRender = images && images.length > 0 ? images.map((image, idx) => {
        const imgsrc = IMAGE_URL + image.filename;
        return <div className='post-image' key={image.filename + "___" + idx}>
            <img src={imgsrc} alt="" />
            <div className='tools'>
                <button onClick={(e) => selectImage(e, image.filename)} className="btn btn-primary"><i className="fa fa-check"></i></button>
                <button onClick={(e) => deleteImage(e, image.filename)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
            </div>
        </div>
    }) : "";

    const deleteImage = (e, image) => {
        if (!window.confirm("Вы точно хотите удалить изображение?")) return false;
        ImagesService.del(image).then((response) => {
            let imgs = images.filter((x) => x.filename != image);
            setImages(imgs);
        });

    }

    const selectImage = (e, image) => {
        e.preventDefault();
        e.stopPropagation();
        onSelectImage(image);
    }

    useEffect(() => {
        getImages();
        if (onClose) {
            let myModal = document.getElementById(id)
            myModal.addEventListener('hidden.bs.offcanvas', event => {
                onClose();
            })
        }
        if (show) {
            let myOffcanvas = new window.bootstrap.Offcanvas(document.getElementById(id));
            myOffcanvas.show();
            setOffcanvasObj(myOffcanvas);
        }
        if (!show && offcanvasObj) {
            offcanvasObj.hide();
        }
    }, [show]);

    return (<div>
        <div className="offcanvas offcanvas-end" tabindex="-1" id={id} aria-labelledby="offcanvasImagesDialog">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasRightLabel">Выбор изображения</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <div className='images-list'>
                    {imagesRender}
                </div>
                {message && (<div className="alert alert-danger mt-4">
                    {message}
                </div>)}
                {successMessage && (<div className="alert alert-success mt-4">
                    {successMessage}
                </div>)}
            </div>
        </div>
    </div>)
}

export default ImagesDialog