import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import Helper from '../common/Helper';
import Strings from '../common/Strings';
import Toasts from '../common/Toasts';

import './Transactions.css';

import TransactionsStrings from './TransactionsStrings';
import TransactionsService from './TransactionsService';
import TransactionEditor from './TransactionEditor';


const Transactions = ({ userId, inline, onUpdated }) => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [transactions, setTransactions] = useState('');

    const getTransactions = () => {
        if (userId) {
            TransactionsService.getForUser(userId).then(
                (response) => {
                    setTransactions(response);
                },
                (error) => {
                    setMessage(Helper.errorToString(error));
                }
            );
        } else {
            TransactionsService.get().then(
                (response) => {
                    setTransactions(response);
                },
                (error) => {
                    setMessage(Helper.errorToString(error));
                }
            );
        }
    }

    const del = (e, id) => {
        TransactionsService.del(id).then(
            (response) => {
                refresh();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            });
    }

    const onTransactionUpdated = () => {
        refresh()
        onUpdated();
    }

    const refresh = () => {
        getTransactions();
    }

    useEffect(() => {
        refresh();
    }, []);

    const transactionsRender = !transactions || transactions.length == 0 ? "Транзакций пока нет" : transactions.map((tr) => {
        return <div className='mb-4' key={tr.id}>
            <div className={"card card-transaction " + (tr.amount < 0 ? "card-transaction-outcome" : "card-transaction-income")}>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Сумма: {tr.amount} баллов</li>
                    <li className="list-group-item">Дата: {format(parseISO(tr.creation_date), "dd.MM.yyyy HH:mm")}</li>
                    <li className="list-group-item d-flex">
                        {/* <button className="btn btn-secondary" onClick={(e) => navigate("/incidents/" + incident.id)}><i className="fa fa-pencil"></i></button> */}
                        {/* <button role="button" type="button" data-bs-toggle="modal" data-bs-target={"#modalUser" + user.id} aria-expanded="false" aria-controls={"#collapseUser" + user.id} className="btn btn-secondary"><i className="fa fa-pencil"></i></button> */}
                        <button onClick={(e) => del(e, tr.id)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
                    </li>
                </ul>
                <div className="card-body">
                    <p className="card-text">{tr.description}</p>
                </div>

            </div>
        </div >
    });

    return (<div>
        <div className="container-fluid">
            {!inline && <MainTitle title="Транзакции" />}
            {userId && <TransactionEditor userId={userId} onUpdated={onTransactionUpdated} />}
            <div>
                {transactionsRender}
            </div>
            {message && (<div className="alert alert-success mt-4">
                {message}
            </div>)}
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}
        </div>
    </div>)
}

export default Transactions