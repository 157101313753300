import axios from "axios";
import AuthService from "../auth/AuthService";
import originHeaders from "../auth/AuthService";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const getCurrentUser = () => {
    return axios.get(API_URL + 'users/current', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
    // .catch(function (error) {
    //     console.log(error);
    // })
    // .finally(function () {

    // });

};

const getUsers = (page, page_size, search_query) => {
    return axios.get(API_URL + 'users?page=' + page + "&page_size=" + page_size + (search_query ? ("&search_query=" + search_query) : ""), AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });

};

const getUser = (userId) => {
    return axios.get(API_URL + 'users/' + userId, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });

};

const getGroups = () => {
    return axios.get(API_URL + 'users/usergroups', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
}

const getFeatures = (userId) => {
    return axios.get(API_URL + 'users/features/' + userId, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });

};

const getMyFeatures = () => {
    return axios.get(API_URL + 'users/features/me', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });

};

const createUser = (data) => {
    return axios.post(API_URL + "users", data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const deleteUser = (userId) => {
    return axios.delete(API_URL + "users/" + userId,
        AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const createGroup = (name) => {
    return axios.post(API_URL + "usergroups", {
        name
    }, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const updateUser = (id, email, user_groups, disabled) => {
    return axios.post(API_URL + "users/" + id, {
        id: id,
        email,
        user_groups: user_groups,
        disabled: disabled
    }, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const editGroup = (name) => {
    return axios.put(API_URL + "usergroups", {
        name
    }, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const deleteGroup = (groupId) => {
    return axios.delete(API_URL + "usergroups/" + groupId,
        AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const resetPassword = (email) => {
    return axios.post(API_URL + "users/reset_password", {
        email
    }, originHeaders)
        .then((response) => {
            return response.data;
        });
}

const importUsers = (formData, onUploadProgress) => {
    let headers = AuthService.getTokenHeaders();
    headers.onUploadProgress = onUploadProgress;
    console.log(formData);
    return axios.post(API_URL + 'users/import', formData, headers).then(response => {
        return response.data;
    }).catch(err => console.log(err))
}

const exportUsers = () => {
    return axios({
        url: API_URL + 'users/download',
        method: 'GET',
        responseType: 'blob',
        headers: AuthService.getTokenHeaders().headers
    }, AuthService.getTokenHeaders()).then((response) => {
        return response;
    });
}

const getQuestionnaire = () => {
    return axios.get(API_URL + 'users/questionnaire', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
}

const setAnswers = (data) => {
    return axios.post(API_URL + "users/questionnaire", data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const proofResetPassword = (data) => {
    return axios.post(API_URL + "users/proof_reset_password", data, originHeaders)
        .then((response) => {
            return response.data;
        });
}

const getTelegramGroups = () => {
    return axios.get(API_URL + 'users/tg_groups', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        });
}

const mute = (user_id) => {
    return axios.post(API_URL + "users/" + user_id + "/mute", {}, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const unmute = (user_id) => {
    return axios.post(API_URL + "users/" + user_id + "/unmute", {}, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const ban = (user_id) => {
    return axios.post(API_URL + "users/" + user_id + "/ban", {}, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const unban = (user_id) => {
    return axios.post(API_URL + "users/" + user_id + "/unban", {}, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}
const kick = (user_id) => {
    return axios.post(API_URL + "users/" + user_id + "/kick", {}, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const UsersService = {
    getUser,
    getUsers,
    getGroups,
    createUser,
    createGroup,
    deleteGroup,
    editGroup,
    resetPassword,
    importUsers,
    exportUsers,
    deleteUser,
    getCurrentUser,
    getQuestionnaire,
    updateUser,
    setAnswers,
    getFeatures,
    getMyFeatures,
    proofResetPassword,
    getTelegramGroups,

    mute,
    unmute,
    ban,
    unban,
    kick
}

export default UsersService;