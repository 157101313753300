import { Routes, Route, Link, useNavigate, Navigate, NavLink } from "react-router-dom";

import logo from './logo.svg';
import './App.css';
import QuestionnaireEditor from './components/questionnaire/QuestionnaireEditor';
import Home from "./components/Home";
import Questionnaires from "./components/questionnaire/Questionnaires";
import Users from "./components/users/Users";
import UnderConstruction from "./components/common/UnderConstruction";
import Posts from "./components/posts/Posts";
import StopWords from "./components/stopwords/StopWords";
import { useState } from "react";
import Incidents from "./components/stopwords/Incidents";

import RestorePassword from './components/auth/RestorePassword';
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ForgotPassword from './components/auth/ForgotPassword';

import AuthService from "./components/auth/AuthService";
import AuthRoute from "./components/auth/AuthRoute";
import NotFoundPage from "./components/NotFoundPage";
import Infopages from "./components/infopages/Infopages";
import Settings from "./components/settings/Settings";
import Categories from "./components/posts/Categories";

import Toasts from "./components/common/Toasts";
import Hashtags from "./components/hashtags/Hashtags";
import Analytics from "./components/analytics/Analytics";

function App() {

    const logOut = () => {
        AuthService.logout();
        window.location.reload();
    };

    const [currentAuth, setCurrentAuth] = useState(AuthService.getCurrentAuth());
    const [currentUser, setCurrentUser] = useState(undefined);
    const [message, setMessage] = useState();
    const [showModerationSub, setShowModerationSub] = useState(false);
    const [showPostsSub, setShowPostsSub] = useState(false);
console.log(currentAuth);
    return (
        <div>
            <header>

            </header>
            <main>
                <div className="main-sildebar">
                    <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                        <span className="fs-4">Меню</span>
                    </a>
                    <hr />
                    {currentAuth &&
                        <ul className="nav nav-pills flex-column mb-auto">
                            <li className="nav-item">
                                <NavLink to='/' className={({ isActive, isPending }) =>
                                    isPending ? "pending nav-link text-white" : isActive ? "active nav-link text-white" : "nav-link text-white"}>
                                    <em className="fa fa-fw fa-home"></em>
                                    Главная
                                </NavLink>
                            </li>
                            <li>
                                <button onClick={() => setShowPostsSub(!showPostsSub)} className="nav-link text-white" aria-expanded={showPostsSub} aria-controls="moderation-collapse">
                                    <em className="fa fa-fw fa-newspaper-o"></em> Публикации
                                </button>
                                <div className={"collapse" + (showPostsSub ? " show" : "")} id="moderation-collapse">
                                    <ul className="list-unstyled fw-normal pb-1 small ps-3">
                                        <li>
                                            <NavLink to='/posts' className={({ isActive, isPending }) =>
                                                isPending ? "pending nav-link text-white" : isActive ? "active nav-link text-white" : "nav-link text-white"}>
                                                <em className="fa fa-fw fa-newspaper-o"></em>
                                                Публикации
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/categories' className={({ isActive, isPending }) =>
                                                isPending ? "pending nav-link text-white" : isActive ? "active nav-link text-white" : "nav-link text-white"}>
                                                <em className="fa fa-fw fa-list"></em>
                                                Рубрики
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <NavLink to='/polls' className={({ isActive, isPending }) =>
                                    isPending ? "pending nav-link text-white" : isActive ? "active nav-link text-white" : "nav-link text-white"}>
                                    <em className="fa fa-fw fa-question-circle-o"></em>
                                    Викторины
                                </NavLink>
                            </li>
                            
                            <li>
                                <NavLink to='/infopages' className={({ isActive, isPending }) =>
                                    isPending ? "pending nav-link text-white" : isActive ? "active nav-link text-white" : "nav-link text-white"}>
                                    <em className="fa fa-fw fa-sitemap"></em>
                                    Инфостраницы
                                </NavLink>
                            </li>
                            <li>
                                <button onClick={() => setShowModerationSub(!showModerationSub)} className="nav-link text-white" aria-expanded={showModerationSub} aria-controls="moderation-collapse">
                                    <em className="fa fa-fw fa-shield"></em> Модерация
                                </button>
                                <div className={"collapse" + (showModerationSub ? " show" : "")} id="moderation-collapse">
                                    <ul className="list-unstyled fw-normal pb-1 small ps-3">
                                        <li>
                                            <NavLink to='/stopwords' className={({ isActive, isPending }) =>
                                                isPending ? "pending nav-link text-white" : isActive ? "active nav-link text-white" : "nav-link text-white"}>
                                                <em className="fa fa-fw fa-remove"></em>
                                                Стоп-фразы
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/incidents' className={({ isActive, isPending }) =>
                                                isPending ? "pending nav-link text-white" : isActive ? "active nav-link text-white" : "nav-link text-white"}>
                                                <em className="fa fa-fw fa-remove"></em>
                                                Удалённые сообщения
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <NavLink to='/users' className={({ isActive, isPending }) =>
                                    isPending ? "pending nav-link text-white" : isActive ? "active nav-link text-white" : "nav-link text-white"}>
                                    <em className="fa fa-fw fa-users"></em>
                                    Пользователи
                                </NavLink>
                            </li>
                            {/* <li>
                                <NavLink to='/automessages' className={({ isActive, isPending }) =>
                                    isPending ? "pending nav-link text-white" : isActive ? "active nav-link text-white" : "nav-link text-white"}>
                                    <em className="fa fa-fw fa-list"></em>
                                    Авторассылки
                                </NavLink>
                            </li> */}
                            <li>
                                <NavLink to='/hashtags' className={({ isActive, isPending }) =>
                                    isPending ? "pending nav-link text-white" : isActive ? "active nav-link text-white" : "nav-link text-white"}>
                                    <em className="fa fa-fw fa-hashtag"></em>
                                    Хэштеги
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/analytics' className={({ isActive, isPending }) =>
                                    isPending ? "pending nav-link text-white" : isActive ? "active nav-link text-white" : "nav-link text-white"}>
                                    <em className="fa fa-fw fa-dashboard"></em>
                                    Аналитика
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/settings' className={({ isActive, isPending }) =>
                                    isPending ? "pending nav-link text-white" : isActive ? "active nav-link text-white" : "nav-link text-white"}>
                                    <em className="fa fa-fw fa-gears"></em>
                                    Настройки
                                </NavLink>
                            </li>
                        </ul>}

                    {!currentAuth && (<div className="dropdown"><ul className="navbar-nav mb-2 mb-lg-0 d-flex flex-row">

                        <li className="nav-item me-2">
                            <a className="btn btn-outline-primary btn-sm" href="/login">
                                Войти
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/register" className="btn btn-secondary btn-sm">
                                Зарегистрироваться
                            </a>
                        </li>
                    </ul></div>)}
                    <hr />


                    {currentAuth &&
                        <div className="dropdown">
                            <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <strong>admin</strong>
                            </a>


                            <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                                {/* <li><a className="dropdown-item" href="#">Настройки</a></li>
                                <li><a className="dropdown-item" href="#">Профиль</a></li> */}
                                <li><hr className="dropdown-divider" /></li>
                                <li><a onClick={logOut} className="dropdown-item" href="#"><i className="fa fa-power-off"></i> Выход</a></li>
                            </ul>



                            {/* {currentAuth && (<ul className="navbar-nav mb-2 mb-lg-0 align-items-center">
                            {currentUser && (<li className="nav-item me-2"><a href="/balance">Баланс: {currentUser.balance} баллов</a></li>)}
                            <li className="nav-item me-2">
                                <a className="btn btn-outline-primary" href="/personal">
                                    <i className="fa fa-user"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/" className="btn btn-secondary" onClick={logOut}>
                                    <i className="fa fa-power-off"></i>
                                </a>
                            </li>
                        </ul>)}
                        {!currentAuth && (<ul className="navbar-nav mb-2 mb-lg-0">

                            <li className="nav-item me-2">
                                <a className="btn btn-outline-primary btn-sm" href="/login">
                                    Войти
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/register" className="btn btn-secondary btn-sm">
                                    Зарегистрироваться
                                </a>
                            </li>
                        </ul>)} */}





                        </div>}
                </div>
                <div className="main-content">
                    <Routes>
                        <Route exact path={"/"} element={<AuthRoute><Home /></AuthRoute>} />

                        <Route exact path={"/register"} element={<Register />} />
                        <Route exact path={"/login"} element={<Login setCurrentAuth={setCurrentAuth} />} />
                        <Route exact path={"/restore_pass"} element={<RestorePassword />} />
                        <Route exact path={"/restore-password"} element={<ForgotPassword />} />


                        <Route exact path={"/polls"} element={<AuthRoute><Questionnaires /></AuthRoute>} />
                        <Route exact path={"/polls/:questionaireId"} element={<AuthRoute><Questionnaires /></AuthRoute>} />
                        <Route exact path={"/users"} element={<AuthRoute><Users /></AuthRoute>} />
                        <Route exact path={"/users/:userId"} element={<AuthRoute><Users /></AuthRoute>} />

                        <Route exact path={"/users"} element={<AuthRoute><UnderConstruction /></AuthRoute>} />
                        <Route exact path={"/automessages"} element={<AuthRoute><UnderConstruction /></AuthRoute>} />
                        
                        <Route exact path={"/analytics"} element={<AuthRoute><Analytics /></AuthRoute>} />

                        <Route exact path={"/stopwords"} element={<AuthRoute><StopWords /></AuthRoute>} />
                        <Route exact path={"/incidents"} element={<AuthRoute><Incidents /></AuthRoute>} />

                        <Route exact path={"/posts"} element={<AuthRoute><Posts /></AuthRoute>} />
                        <Route exact path={"/posts/:postId"} element={<AuthRoute><Posts /></AuthRoute>} />

                        <Route exact path={"/categories"} element={<AuthRoute><Categories /></AuthRoute>} />
                        <Route exact path={"/categories/:categoryId"} element={<AuthRoute><Categories /></AuthRoute>} />

                        <Route exact path={"/infopages"} element={<AuthRoute><Infopages /></AuthRoute>} />
                        <Route exact path={"/infopages/:infopageId"} element={<AuthRoute><Infopages /></AuthRoute>} />

                        <Route exact path={"/hashtags"} element={<AuthRoute><Hashtags /></AuthRoute>} />
                        <Route exact path={"/hashtags/:hashtagId"} element={<AuthRoute><Hashtags /></AuthRoute>} />

                        <Route exact path={"/settings"} element={<AuthRoute><Settings /></AuthRoute>} />

                        

                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                    <Toasts message={message} setMessage={setMessage} />
                </div>
            </main>
            <footer>

            </footer>
        </div>
    );
}

export default App;
