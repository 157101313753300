import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import MainTitle from "../common/MainTitle";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import AuthService from "./AuthService";
import UsersService from "../users/UsersService";

import Strings from "../common/Strings";

const RestorePassword = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();



    const [message, setMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();

    const token = searchParams.get("token");

    const validationSchema = yup.object().shape({
        access_token: yup.string().required(),
        password: yup.string()
            .required('Введите пароль')
            .min(6, 'Пароль должен быть больше 6 символов')
            .max(40, 'Пароль не должен превышать 40 символов'),
        confirmPassword: yup.string()
            .required('Повторите пароль')
            .oneOf([yup.ref('password'), null], 'Пароли не совпадают'),

    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            access_token: token
        }
    });

    const handleRestoreSubmit = (data) => {

        UsersService.proofResetPassword(data).then(
            (response) => {
                console.log(response);
                if (response.type = "ok") {
                    reset();
                    navigate("/login");
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setMessage(Strings.errors[resMessage] ? Strings.errors[resMessage] : resMessage);
            }
        );
    };

    useEffect(() => {
        if (AuthService.getCurrentAuth()) {
            navigate("/");
        }



        if (!token) {
            navigate("/");
        }

    }, []);

    return <div>

        <MainTitle title="Восстановление пароля" />
        <section>
            <div className="container-fluid">
                <p>Введите новый пароль.</p>
                <form onSubmit={handleSubmit(handleRestoreSubmit)}>
                    <input
                        name="access_token"
                        type="hidden"
                        {...register('access_token')}
                        className={`form-control ${errors.access_token ? 'is-invalid' : ''}`}
                        autoComplete="off" />
                    <div className="form-error">{errors.access_token?.message}</div>
                    <div className="form-group">
                        <label>Пароль</label>
                        <input
                            name="password"
                            type="password"
                            {...register('password')}
                            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                            autoComplete="off" />
                        <div className="form-error">{errors.password?.message}</div>
                    </div>

                    <div className="form-group">
                        <label>Повторите пароль</label>
                        <input
                            name="confirmPassword"
                            type="password"
                            {...register('confirmPassword')}
                            className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                            autoComplete="off" />
                        <div className="form-error">{errors.confirmPassword?.message}</div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <button type="submit" className="btn btn-primary">
                            Отправить
                        </button>
                    </div>
                </form>
                {message && (<div className="alert alert-danger mt-4">
                    {message}
                </div>)}
                {successMessage && (<div className="alert alert-success mt-4">
                    {successMessage}
                </div>)}
            </div>
        </section>


    </div>
}

export default RestorePassword