import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import UsersService from "./UsersService";
import Helper from "../common/Helper";

const UserEditor = ({ groups, userInfo, onUserUpdated }) => {

    const [message, setMessage] = useState("");

    useEffect(() => {

    }, []);

    const groupOptions = !groups ? "" : groups.map((group) =>
        <option key={group} value={group}>{group}</option>
    );

    const validationSchema = yup.object().shape({
        name: yup.string()
            .required('Введите имя'),
        phone: yup.string()
            .required('Введите номер телефона'),
        email: yup.string()
            .required('Введите email')
            .email('Введите корректный email'),
        password: yup.string()
            .required('Введите пароль')
            .min(6, 'Пароль должен быть больше 6 символов')
            .max(40, 'Пароль не должен превышать 40 символов'),
        confirmPassword: yup.string()
            .required('Повторите пароль')
            .oneOf([yup.ref('password'), null], 'Пароли не совпадают')
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            email: userInfo && userInfo.email,
            phone: userInfo && userInfo.phone,
            //userGroup: [0],
            disabled: userInfo && userInfo.disabled == 1
        }
    });

    const handleUpdate = (data) => {
        data.groups = data.groups ? (Array.isArray(data.groups) ? data.groups : [data.groups]) : [];
        data.disabled = data.disabled ? 1 : 0
        UsersService.createUser(data).then(
            (response) => {
                onUserUpdated();
                reset();
                //reset();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );

    };

    return (
        <div className="">
            <form onSubmit={handleSubmit(handleUpdate)} autoComplete="off">
                <div className="form-group">
                    <label>Имя</label>
                    <input role="presentation"
                        name="name"
                        type="text"
                        {...register('name')}
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.name?.message}</div>
                </div>
                <div className="form-group">
                    <label>Телефон</label>
                    <input role="presentation"
                        name="email"
                        type="text"
                        {...register('phone')}
                        className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                        autoComplete="new-phone" />
                    <div className="form-error">{errors.phone?.message}</div>
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input role="presentation"
                        name="email"
                        type="text"
                        {...register('email')}
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        autoComplete="new-email" />
                    <div className="form-error">{errors.email?.message}</div>
                </div>

                <div className="form-group">
                    <label>Группа</label>
                    <select name="groups"
                        {...register('groups')}
                        className="form-control"
                    >
                        <option value="">Без группы</option>
                        {groupOptions}
                    </select>
                </div>

                <div className="form-group">
                    <label>Пароль</label>
                    <input
                        name="password"
                        type="password"
                        {...register('password')}
                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                        autoComplete="new-password" />
                    <div className="form-error">{errors.password?.message}</div>
                </div>

                <div className="form-group">
                    <label>Повторите пароль</label>
                    <input
                        name="confirmPassword"
                        type="password"
                        {...register('confirmPassword')}
                        className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                        autoComplete="new-password" />
                    <div className="form-error">{errors.confirmPassword?.message}</div>
                </div>

                <div className="form-check">
                    <input className="form-check-input"
                        id={"disabledCheck"}
                        name="disabled"
                        type="checkbox"
                        {...register('disabled')}
                    />
                    <label htmlFor={"disabledCheck"}>Отключен</label>
                </div>

                <div className="mt-2 d-flex align-items-center justify-content-between">
                    <button type="submit" className="btn btn-primary">
                        Обновить
                    </button>
                </div>
            </form>
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
        </div>
    );
};

export default UserEditor;