import axios from 'axios';
import AuthService from '../auth/AuthService';
import originHeaders from '../auth/AuthService';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const getAntimatText = () => {
    return axios.get(API_URL + 'antimat', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

const updateAntimatText = (data) => {
    return axios.post(API_URL + 'antimat', data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const getIncidents = (page, page_size) => {
    return axios.get(API_URL + 'antimat/incidents?page=' + page + "&page_size=" + page_size, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

const getIncidentsForUser = (user_chat_id) => {
    return axios.get(API_URL + 'antimat/incidents/' + user_chat_id, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

// const del = (id) => {
//     return axios.delete(API_URL + 'someurl' + id,
//         AuthService.getTokenHeaders())
//         .then((response) => {
//             return response.data;
//         });
// }

const StopWordsService = {
    getAntimatText,
    updateAntimatText,
    getIncidents,
    getIncidentsForUser
}

export default StopWordsService;