import React, { useState, useEffect } from 'react';
import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import { useNavigate, useParams } from "react-router-dom";
import './Infopages.css';
import InfopageEditor from './InfopageEditor';
import { format, parseISO } from 'date-fns';

import infopage_thumb from './infopage_thumb.jpg';
import imagenotfound from './../common/imgnotfound.jpg';

import infopageStrings from './InfopageStrings';
import InfopagesService from './InfopagesService';
import Helper from '../common/Helper';
import Strings from '../common/Strings';

import Toasts from '../common/Toasts';

const IMAGE_URL = process.env.REACT_APP_API_BASE_STATIC_URL + "images/";

const Infopages = () => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [infopages, setInfopages] = useState('');
    const [statuses, setStatuses] = useState('');
    const [showNewModal, setShowNewModal] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(Helper.defaults.PAGE_SIZE);
    const [showMore, setShowMore] = useState(false);

    const [infopageCopy, setInfopageCopy] = useState("");

    const navigate = useNavigate();
    const { infopageId } = useParams();

    const deleteInfopage = (e, infopageId) => {
        if (!window.confirm("Вы уверены, что хотите удалить страницу?")) return;
        InfopagesService.del(infopageId).then(
            (response) => {
                refresh();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    };

    const getInfopages = () => {
        InfopagesService.get(1, currentPage * pageSize).then((response) => {
            setShowMore(response.length == pageSize);
            
            const all = [];
            if(infopageId && !response.find(x => x.id == infopageId)) {
                InfopagesService.getOne(infopageId).then((infopageResponse) => {
                    all.push(...response);
                    all.push(infopageResponse);
                    all.forEach(element => {
                        element.parent_id = element.parent_id ? element.parent_id : "";
                        element.previous_id = element.previous_id ? element.previous_id : "";
                    });
                    setInfopages(all);
                });
            } else {
                all.push(...response);
                all.forEach(element => {
                    element.parent_id = element.parent_id ? element.parent_id : "";
                    element.previous_id = element.previous_id ? element.previous_id : "";
                });
                setInfopages(all);
            }
            
        }, (error) => {
            setMessage(Helper.errorToString(error));
        });
    }

    const loadMore = () => {
        const page = currentPage ? currentPage + 1 : 1;
        InfopagesService.get(page, pageSize).then((response) => {
            const all = [];
            if (infopages) {
                all.push(...infopages);
            }
            if (response) {
                response.forEach(element => {
                    if (!all.find((x) => x.id == element.id)) {
                        all.push(element);
                    }
                });

            }
            all.forEach(element => {
                element.parent_id = element.parent_id ? element.parent_id : "";
                element.previous_id = element.previous_id ? element.previous_id : "";
            });

            setInfopages(all);
            setCurrentPage(page);

            setShowMore(response.length == pageSize);

        });
    }

    const createcopy = (id) => {
        const infopageToCopy = infopages.find(x => x.id == id);
        if (infopageToCopy) {
            const infopageCopyData = structuredClone(infopageToCopy);
            delete infopageCopyData.id;
            delete infopageCopyData.creation_date;
            setInfopageCopy(infopageCopyData);
            setShowNewModal(true);
        }
    }

    const refresh = () => {
        getInfopages();
    }

    useEffect(() => {

        refresh();
        setStatuses(["draft", "queued", "published"]);

    }, []);
    // {
    //     "id": "1",
    //     "menu_title": "page1",
    //     "text": "This is the index page with tons of data.",
    //     "parent_id": "5",
    //     "position": 1,
    //     "images": [
    //         "1.jpg"
    //     ],
    //     "video": [
    //         465468
    //     ],
    //     "file": "test.dox",
    //     "questionnaire": "add-file",
    //     "command": "/add",
    //     "webapp_url": "page1",
    //     "previous_id": null,
    //     "webapp_callback_questionnaire": "questionnaire"
    // },
    const infopagesRender = !infopages ? "" : infopages.map((infopage) => {

        const infopageImages = infopage.images && infopage.images.length > 0 ?
            infopage.images.map((image, idx) =>
                <img key={"img_" + idx} src={IMAGE_URL + image} alt="" onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = imagenotfound;
                    return false;
                }} />)
            : "";

        return <div className='mb-4' key={infopage.id}>
            <div className="card card-infopage">
                <div className="card-body">
                    <div className="card-body-images">{infopageImages}</div>
                    <p className="card-text">{infopage.text}</p>
                </div>
                <ul className="list-group list-group-flush">
                    <li className='list-group-item'>ID: {infopage.id} | Кнопка в меню: {infopage.menu_title}</li>
                    <li className='list-group-item'>Родительская страница: {infopage.parent_id ? infopage.parent_id : "Нет"}</li>
                    {/* <li className="list-group-item">Статус: {infopageStrings['status'][infopage.status]}</li> */}
                    {/* <li className="list-group-item">Дата создания: {infopage.creation_date}</li> */}
                    {infopage.questionnaire && <li className="list-group-item">Запускает опросник: {infopage.questionnaire}</li>}
                    <li className="list-group-item d-flex">
                        <button className="btn btn-secondary" onClick={(e) => navigate("/infopages/" + infopage.id)}><i className="fa fa-pencil"></i></button>
                        <button className="btn btn-secondary" onClick={(e) => createcopy(infopage.id)}><i className="fa fa-copy"></i></button>
                        <button onClick={(e) => deleteInfopage(e, infopage.id)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
                    </li>
                </ul>
            </div>
            {infopageId && (infopage.id == infopageId) &&
                <Modal onClose={() => navigate("/infopages")} show={true} title="Редактирование инфостраницы" id={"modalInfopage" + infopage.id}>
                    <InfopageEditor data={infopage} infopages={infopages} onUpdated={refresh} statuses={statuses} />
                </Modal>}
        </div>
    });

    return (<div>
        <div className="container-fluid">
            <MainTitle title="Инфостраницы (автоответы)" />
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}
            <div className="control-panel">
                <button type="button" className="btn btn-primary nowrap" onClick={() => setShowNewModal(true)}>
                    Новая инфостраница
                </button>
                {/* <div className="input-group search-panel">
                    <input type="text" className="form-control" placeholder="Найти..."
                        aria-label="Найти..." aria-describedby="button-search" />
                    <button className="btn btn-outline-secondary" type="button" id="button-search">Найти</button>
                </div> */}
            </div>

            <div>
                {infopagesRender}
            </div>
            {showMore &&
                <div className='text-center'><button onClick={loadMore} className='btn btn-success'>Показать еще</button></div>}
            {showNewModal && <Modal show={true} title="Новая инфостраница" id="newModal" onClose={() => setShowNewModal(false)}>
                <InfopageEditor infopages={infopages} data={infopageCopy} onUpdated={refresh} statuses={statuses} />
            </Modal>}
            <Toasts message={message} setMessage={setMessage} />
        </div>
    </div>)
}

export default Infopages