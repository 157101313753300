import React, { useState, useEffect } from 'react';


const Toasts = ({ message, setMessage }) => {

    const [messages, setMessages] = useState([]);

    useEffect(() => {

        if (message) {
            setMessages([...messages, message]);
            setMessage("");
        }
    }, [message]);

    const closeMsg = (idx) => {
        messages.splice(idx, 1);
        const msgs = [...messages];
        setMessages(msgs);
    }

    const messagesRender = !messages ? "" : messages.map((msg, idx) => {
        return <div key={"msg" + idx} className="toast show bg-danger" role="alert" aria-live="assertive" aria-atomic="true">
            <div className="toast-header">
                <strong className="me-auto">Ошибка</strong>
                <button type="button" className="btn-close" onClick={(e) => closeMsg(idx)} aria-label="Close"></button>
            </div>
            <div className="toast-body">
                {msg}
            </div>
        </div>
    });

    return (<div>
        {messages.length > 0 && <div aria-live="polite" aria-atomic="true" className='toasts-position'>
            <div className="toast-container top-0 end-0 p-3">
                {messagesRender}
            </div>
        </div>}
    </div>)
}

export default Toasts