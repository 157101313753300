import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import Helper from '../common/Helper';
import Strings from '../common/Strings';
import Toasts from '../common/Toasts';


const TableWidget = ({ title, data }) => {

    const [message, setMessage] = useState('');

    useEffect(() => {

    }, []);

    const dataRender = !data ? "" : data.map((x, idx) => {
        return <div className='table-widget-row' key={"row" + idx}>
            <div className='row' >
                <div className='col-8'>{x.creation_date && (format(parseISO(x.creation_date), "dd.MM.yyyy HH:mm") + ": " )}{x.name}</div>
                <div className='col-4 text-end'>{x.value}</div>
            </div>
        </div>
    });

    return (<div className='table-widget'><p className='h4'>{title}</p>
        {dataRender}
    </div>)
}

export default TableWidget