import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import CategoriesService from './CategoriesService';
import Modal from '../common/Modal';
import CategoryEditor from './CategoryEditor';
import Helper from '../common/Helper';
import MainTitle from '../common/MainTitle';

const Categories = () => {

    const [message, setMessage] = useState('');
    const { categoryId } = useParams();
    const navigate = useNavigate();

    const [categories, setCategories] = useState('');

    const getCategories = () => {
        CategoriesService.get().then((response) => {
            console.log(response);
            setCategories(response);
        });
    }

    const deleteCategory = (e, id) => {
        if (!window.confirm("Вы уверены, что хотите удалить категорию?")) return;
        CategoriesService.del(id).then(
            (response) => {
                refresh();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    };

    const refresh = () => {
        getCategories();
    }


    const catsRender = !categories ? "" : categories.map((category) => {
        return <div className='mb-4' key={category.id}>
            <div className="card card-post">
                <div className="card-body">{category.id}: {category.name}</div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex">
                        <button className="btn btn-secondary" onClick={(e) => navigate("/categories/" + category.id)}><i className="fa fa-pencil"></i></button>
                        <button onClick={(e) => deleteCategory(e, category.id)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
                    </li>
                </ul>
            </div>
            {
                categoryId && (category.id == categoryId) &&
                <Modal onClose={() => navigate("/categories")} show={true} title="Редактирование категории" id={"modal" + category.id}>
                    <CategoryEditor data={category} onUpdated={refresh} />
                </Modal>
            }
        </div>
    });

    useEffect(() => {
        getCategories();
    }, []);

    return (<div>
        <div className="container-fluid">
            <MainTitle title="Рубрики" />
            <div className="control-panel">
                <button type="button" className="btn btn-primary nowrap" data-bs-toggle="modal" data-bs-target="#newModal">
                    Новая рубрика
                </button>
            </div>

            <div>
                {catsRender}
            </div>
            <Modal onClose={() => navigate("/categories")} title="Новая рубрика" id="newModal">
                <CategoryEditor onUpdated={refresh} />
            </Modal>
        </div>
    </div>)
}

export default Categories