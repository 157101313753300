import { Fragment } from "react";
import MainTitle from "../common/MainTitle";

import React, { useState, useRef, useEffect } from "react";


function DisabledUser() {

    return (
        <Fragment>
            <MainTitle title="Учетная запись отключена" />
            <section>

                <div className='container-fluid'>
                    <h2>Ваша учетная запись отключена</h2>
                    <p>Пожалуйста, свяжитесь с администрацией.</p>
                </div>
            </section>
        </Fragment>
    );
}

export default DisabledUser;