import axios from 'axios';
import AuthService from '../auth/AuthService';
import originHeaders from '../auth/AuthService';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const get = (pub_id) => {
    return axios.get(API_URL + 'publications/' + pub_id + "/comments", AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

const create = (data) => {
    return axios.post(API_URL + 'publications/' + data.publication_id + "/comments", data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const update = (data) => {
    return axios.post(API_URL + 'publications/' + data.publication_id + "/comments/" + data.id, data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const del = (pub_id, comment_id) => {
    return axios.delete(API_URL + 'publications/' + pub_id + "/comments/" + comment_id,
        AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const CommentsService = {
    get,
    create,
    update,
    del
}

export default CommentsService;