import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { format, parseISO, addDays, addMonths, addYears } from 'date-fns';


import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import Helper from '../common/Helper';
import Strings from '../common/Strings';
import Toasts from '../common/Toasts';

import './Analytics.css';

import AnalyticsStrings from './AnalyticsStrings';
import AnalyticsService from './AnalyticsService';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ChartWidget from './ChartWidget';
import NumberWidget from './NumberWidget';
import TableWidget from './TableWidget';

const UserAnalytics = ({ userId, setMessage }) => {

    const [successMessage, setSuccessMessage] = useState('');

    const [startDate, setStartDate] = useState(format(addDays(new Date(), -7), "yyyy-MM-dd"));
    const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));

    const [dateMode, setDateMode] = useState("week");

    const [userSubscription, setuserSubscription] = useState("");
    const [chatReactionsStat, setChatReactionsStat] = useState("");
    const [hashtagsStat, setHashtagsStat] = useState("");
    const [userReferals, setUserReferals] = useState("");
    const [transactions, setTransactions] = useState("");

    const getWidgets = () => {
        //setWidgets([]);
        AnalyticsService.getUserSubscription(userId).then(
            (response) => {
                const data = response.map((x) => (
                    {
                        creation_date: x.creation_date,
                        name: "Пользователь " + (x.event ? AnalyticsStrings.event[x.event] : "") + (x.source ? (" " + AnalyticsStrings.userSource[x.source]) : ""),
                        value: x.group_chat_name
                    }));
                setuserSubscription({ title: "Действия пользователя с группами", data: data });
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });

        AnalyticsService.getUserReactions(userId, new Date(startDate).toISOString(), new Date(endDate).toISOString()).then(
            (response) => {
                const title = "Количество лайков в чатах";
                const keys = [];

                const data = response.map((x) => {
                    const name = x.date;
                    const info = x.chats_statistic.map((stat) => {
                        return {
                            group_chat_name: stat.group_chat_name,
                            like_count: stat.event_counts.like_count,
                            dislike_count: stat.event_counts.dislike_count
                        }
                    });
                    const dataObj = info.reduce((accumulator, currentValue, idx) => {
                        //accumulator["name_" + idx] = currentValue.group_chat_name;
                        const key1 = "like_count_" + idx;
                        accumulator[key1] = currentValue.like_count;
                        if (!keys.find(x => x.key == key1)) {
                            keys.push({ key: key1, title: currentValue.group_chat_name + " лайк" });
                        }

                        const key2 = "dislike_count_" + idx;
                        accumulator[key2] = currentValue.dislike_count;
                        if (!keys.find(x => x.key == key2)) {
                            keys.push({ key: key2, title: currentValue.group_chat_name + " дизлайк", dashed: true });
                        }

                        return accumulator;
                    }, {});

                    dataObj["name"] = name;
                    return dataObj;
                });

                const lines = keys.map((key, idx) => {
                    return { title: key.title, key: key.key, color: AnalyticsStrings.colors[idx], dashed: key.dashed ? key.dashed : false }
                });

                setChatReactionsStat({ title, data, lines });
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });

        AnalyticsService.getUserHashtags(userId, new Date(startDate).toISOString(), new Date(endDate).toISOString()).then(
            (response) => {
                const title = "Хэштеги в чатах";
                const keys = [];

                const data = response.map((x) => {
                    const name = x.date;
                    const info = x.chats_statistic.map((stat) => {
                        stat.hashtag_counts.group_chat_name = stat.group_chat_name;
                        return stat.hashtag_counts;
                    });
                    const dataObj = info.reduce((accumulator, currentValue, idx) => {
                        //accumulator["name_" + idx] = currentValue.group_chat_name;

                        Object.entries(currentValue).forEach(element => {
                            if (element[0] != "group_chat_name") {
                                const key1 = element[0] + "_" + idx;
                                accumulator[key1] = element[1];
                                if (!keys.find(x => x.key == key1)) {
                                    keys.push({ key: key1, title: currentValue.group_chat_name + " " + element[0] });
                                }
                            }
                        });



                        return accumulator;
                    }, {});

                    dataObj["name"] = name;
                    return dataObj;
                });

                const lines = keys.map((key, idx) => {
                    return { title: key.title, key: key.key, color: AnalyticsStrings.colors[idx], dashed: key.dashed ? key.dashed : false }
                });

                setHashtagsStat({ title, data, lines });
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });

        AnalyticsService.getUserReferals(userId).then(
            (response) => {
                const data = !response.referals ? [] : response.referals.map((x) => (
                    {
                        name: "Пользователь " + x,
                        value: ""
                    }));
                setUserReferals({ title: "Всего рефералов у пользователя: " + response.referals_count, data: data });
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });

        AnalyticsService.getUserTransactions(userId, new Date(startDate).toISOString(), new Date(endDate).toISOString()).then(
            (response) => {
                const data = response;
                setTransactions(data);
            }, (error) => {
                setMessage(Helper.errorToString(error));
            });
    }

    const changePeriod = (mode, startDate, endDate) => {
        setDateMode(mode);
        switch (mode) {
            case "week":
                setEndDate(format(new Date(), "yyyy-MM-dd"));
                setStartDate(format(addDays(new Date(), -7), "yyyy-MM-dd"));
                break;
            case "month":
                setEndDate(format(new Date(), "yyyy-MM-dd"));
                setStartDate(format(addMonths(new Date(), -1), "yyyy-MM-dd"));
                break;
            case "year":
                setEndDate(format(new Date(), "yyyy-MM-dd"));
                setStartDate(format(addYears(new Date(), -1), "yyyy-MM-dd"));
                break;
            case "quarter":
                setEndDate(format(new Date(), "yyyy-MM-dd"));
                setStartDate(format(addMonths(new Date(), -3), "yyyy-MM-dd"));
                break;
            case "manual":

                break;
            default:
                setDateMode("week");
                setEndDate(format(new Date(), "yyyy-MM-dd"));
                setStartDate(format(addDays(new Date(), -7), "yyyy-MM-dd"));
                break;
        }

    }

    useEffect(() => {
        getWidgets();
    }, [startDate, endDate]);


    const userSubscriptionRender = !userSubscription ? "Нет" : <TableWidget {...userSubscription} />
    const chatReactionsStatRender = !chatReactionsStat ? "" : <ChartWidget {...chatReactionsStat} />;
    const hashtagsStatRender = !hashtagsStat ? "" : <ChartWidget {...hashtagsStat} />;
    const userReferalsRender = !userReferals ? "Нет" : <TableWidget {...userReferals} />

    const transArrayRender = !transactions || transactions.transactions.length == 0
        ? "Транзакций нет"
        : transactions.transactions.map((tr) => {
            return <div className='mb-4' key={tr.id}>
                <div className={"card card-transaction " + (tr.amount < 0 ? "card-transaction-outcome" : "card-transaction-income")}>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">Сумма: {tr.amount} баллов</li>
                        <li className="list-group-item">Дата: {format(parseISO(tr.creation_date), "dd.MM.yyyy HH:mm")}</li>
                    </ul>
                    <div className="card-body">
                        <p className="card-text">{tr.description}</p>
                    </div>

                </div>
            </div >
        })
    const transactionsRender = !transactions ? "" : <div>
        <p>Баланс на начало периода: {transactions.start_period_balance}</p>
        <p>Баланс на конец периода: {transactions.end_period_balance}</p>
        <p>Разница: {transactions.balance_change}</p>
        <div>
            {transArrayRender}
        </div>
    </div>

    return (<div>
        <div className="container-fluid">
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}
            <div className="control-panel">
                <div className="btn-group">
                    <a href="#" onClick={(e) => changePeriod("week")} className={"btn " + (dateMode == "week" ? "btn-primary active" : "btn-outline-primary")}>Неделя</a>
                    <a href="#" onClick={(e) => changePeriod("month")} className={"btn " + (dateMode == "month" ? "btn-primary active" : "btn-outline-primary")}>Месяц</a>
                    <a href="#" onClick={(e) => changePeriod("quarter")} className={"btn " + (dateMode == "quarter" ? "btn-primary active" : "btn-outline-primary")}>Квартал</a>
                    <a href="#" onClick={(e) => changePeriod("year")} className={"btn " + (dateMode == "year" ? "btn-primary active" : "btn-outline-primary")}>Год</a>
                    <a href="#" onClick={(e) => changePeriod("manual")} className={"btn " + (dateMode == "manual" ? "btn-primary active" : "btn-outline-primary")}>Задать вручную</a>
                </div>
                {dateMode == "manual" && <Fragment>
                    <input type="date" className='form-control w100 ms-2' value={startDate} name="startDate" onChange={(e) => setStartDate(e.target.value)} />
                    <input type="date" className='form-control w100 ms-2' value={endDate} name="endDate" onChange={(e) => setEndDate(e.target.value)} />
                </Fragment>}
            </div>
            <div className='row'>
                <div className='col-12 mb-4'>
                    {chatReactionsStatRender}
                </div>
                <div className='col-12 mb-4'>
                    {hashtagsStatRender}
                </div>
                <div className='col-12 mb-4'>
                    {userReferalsRender}
                </div>
                <div className='col-12 mb-4'>
                    {userSubscriptionRender}
                </div>
                <div>
                    <h2>Транзакции за период</h2>
                    {transactionsRender}
                </div>
            </div>
        </div>

    </div>)
}

export default UserAnalytics