import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import Helper from '../common/Helper';
import Strings from '../common/Strings';
import Toasts from '../common/Toasts';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import AnalyticsStrings from './AnalyticsStrings';


const ChartWidget = ({ title, data, lines }) => {
    //console.log(title, data, lines);
    const [message, setMessage] = useState('');
    const [linesVisibility, setLinesVisibility] = useState("");

    useEffect(() => {
        if (lines) {
            const lvis = lines.map((x, idx) => { return { key: x.key, hide: idx >= 5 } })
            setLinesVisibility(lvis);
        }
    }, [lines]);
    
    const lineRender = !lines || !linesVisibility ? "" : lines.map((line, idx) => {
        if(!linesVisibility.find(x => x.key == line.key)) return "";
        const hidden = linesVisibility.find(x => x.key == line.key).hide;
        return <Line key={"line" + idx} hide={hidden} type="monotone" name={line.title} dataKey={line.key} stroke={line.color} strokeDasharray={line.dashed ? "5 5" : ""} />
    });

    const toggleLine = (o) => {
        const found = linesVisibility.find(x => x.key == o.dataKey);
        found.hide = !found.hide;
        setLinesVisibility([...linesVisibility]);
    }

    return (<div><p className='h4'>{title}</p>
        <ResponsiveContainer width="100%" aspect={2}>
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend onClick={toggleLine} layout="vertical" align='right' width={200} verticalAlign='top'/>

                {lineRender}
            </LineChart>
        </ResponsiveContainer>
    </div>)
}

export default ChartWidget