import React, { useState, useEffect, useRef, useId } from 'react';
import './Modal.css';

// window.bootstrap = require('https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.bundle.min.js');

const Modal = ({ children, title, id, show, onClose }) => {

    const [message, setMessage] = useState('');
    const closeModalBtn = useRef();
    const [modalObj, setModalObj] = useState('');

    //console.log(children);
    useEffect(() => {
        if (onClose) {
            let myModal = document.getElementById(id)
            myModal.addEventListener('hidden.bs.modal', event => {
                onClose();
            })
        }
        if (show) {
            let myModal = new window.bootstrap.Modal(document.getElementById(id));
            myModal.show();
            setModalObj(myModal);
        }
        if (!show && modalObj) {
            //let myModal = new window.bootstrap.Modal(document.getElementById(id));
            modalObj.hide();
        }
    }, [show]);

    return (<div className={"modal fade right come-from-modal"} id={id} tabIndex="-1" aria-labelledby="newUserModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <p className="modal-title fs-5 h3">{title}</p>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeModalBtn}></button>
                </div>
                <div className="modal-body">
                    {children}
                </div>
            </div>
        </div>
    </div>)
}

export default Modal