

const MainTitle = ({title}) => {

    return <div className="main-title">
        <div className="">
            <h1>{title}</h1>
        </div>
    </div>
}

export default MainTitle;