import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Incidents from "../stopwords/Incidents";
import Transactions from "../transactions/Transactions";
import TransactionEditor from "../transactions/TransactionEditor";
import { format, parseISO, addDays, addMonths, addYears } from 'date-fns';


import Helper from "../common/Helper";
import AnalyticsService from "../analytics/AnalyticsService";
import UserAnalytics from "../analytics/UserAnalytics";
import UsersService from "./UsersService";
import UsersStrings from "./UsersStrings";

const UserInfo = ({ groups, userInfo, onUpdated, onDeleted }) => {
    const [message, setMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {

    }, []);

    const groupOptions = !groups ? "" : groups.map((group) =>
        <option key={"gr_" + group.id} value={group.id}>{group.name}</option>
    );

    let groupsInfo = userInfo.groups ? userInfo.groups.map((group) => group).join(", ") : "";

    const banUser = (e, id) => {
        if (!window.confirm("Вы уверены, что хотите забанить пользователя?")) return;
        UsersService.ban(id).then(
            (response) => {
                onUpdated();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const unbanUser = (e, id) => {
        UsersService.unban(id).then(
            (response) => {
                onUpdated();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const muteUser = (e, id) => {
        UsersService.mute(id).then(
            (response) => {
                onUpdated();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const unmuteUser = (e, id) => {
        UsersService.unmute(id).then(
            (response) => {
                onUpdated();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const kickUser = (e, id) => {
        if (!window.confirm("Вы уверены, что хотите удалить пользователя из группы?")) return;
        UsersService.kick(id).then(
            (response) => {
                onUpdated();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const deleteUser = (e, id) => {
        if (!window.confirm("Вы уверены, что хотите удалить пользователя? Пользователь будет полностью удалён из системы.")) return;
        UsersService.deleteUser(id).then(
            (response) => {
                onDeleted();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    return (
        <div className="">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="true">Профиль</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="antimat-tab" data-bs-toggle="tab" data-bs-target="#antimat-tab-pane" type="button" role="tab" aria-controls="antimat-tab-pane" aria-selected="false">Антимат</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="transactions-tab" data-bs-toggle="tab" data-bs-target="#transactions-tab-pane" type="button" role="tab" aria-controls="transactions-tab-pane" aria-selected="false">Транзакции</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="analytics-tab" data-bs-toggle="tab" data-bs-target="#analytics-tab-pane" type="button" role="tab" aria-controls="analytics-tab-pane" aria-selected="false">Аналитика</button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">

                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">{userInfo.username && <a target="_blank" href={"https://t.me/" + userInfo.username}>@{userInfo.username}</a>}</li>
                        <li className="list-group-item">{userInfo.phone}</li>
                        <li className="list-group-item">{userInfo.name}</li>
                        {groupsInfo && <li className="list-group-item">{groupsInfo}</li>}
                        {userInfo.email && <li className="list-group-item">{userInfo.email}</li>}
                        <li className="list-group-item">Баланс: {userInfo.balance} баллов</li>
                        {userInfo.status && <li className="list-group-item">{UsersStrings.status[userInfo.status]}</li>}
                        {userInfo.creation_date && <li className="list-group-item">Дата регистрации: {format(parseISO(userInfo.creation_date), "dd.MM.yyyy HH:mm")}</li>}
                    </ul>
                    <div className="card-body">
                        {userInfo.status == "active" && <button onClick={(e) => muteUser(e, userInfo.id)} className="btn btn-secondary">Замьютить</button>}
                        {userInfo.status == "mute" && <button onClick={(e) => unmuteUser(e, userInfo.id)} className="btn btn-secondary">Размьютить</button>}
                        {(userInfo.status == "active" || userInfo.status == "mute") && <button onClick={(e) => banUser(e, userInfo.id)} className="btn btn-warning">Забанить</button>}
                        {userInfo.status != "ban" && <button onClick={(e) => kickUser(e, userInfo.id)} className="btn btn-danger">Удалить из группы</button>}
                        {userInfo.status == "ban" && <button onClick={(e) => unbanUser(e, userInfo.id)} className="btn btn-warning">Разбанить</button>}
                        <button onClick={(e) => deleteUser(e, userInfo.id)} className="btn btn-danger">Удалить из системы</button>
                    </div>
                    <div className="alert alert-info mt-4">
                        <p>Мьют переводит пользователя в режим "Только чтение".</p>
                        <p>Бан удаляет пользователя из группы и пользователь не может снова присоединиться по инвайт-ссылке</p>
                        <p>Удаление из группы приводит к удалению пользователя из группы, пользователь может присоединиться снова по инвайт-ссылке</p>
                        <p>Полное удаление пользователя приводит к его разбану и полному удалению данных о нем из системы.</p>
                    </div>
                    {message && (<div className="alert alert-danger mt-4">
                        {message}
                    </div>)}
                    {successMessage && (<div className="alert alert-success mt-4">
                        {successMessage}
                    </div>)}
                </div>
                <div className="tab-pane fade" id="antimat-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                    <Incidents userId={userInfo.id} inline={true} />
                </div>
                <div className="tab-pane fade" id="transactions-tab-pane" role="tabpanel" aria-labelledby="transactions-tab" tabIndex="0">
                    <p className="ms-3 text-bold">Баланс: {userInfo.balance} баллов</p>
                    
                    <Transactions userId={userInfo.id} inline={true} onUpdated={onUpdated} />

                </div>
                <div className="tab-pane fade" id="analytics-tab-pane" role="tabpanel" aria-labelledby="analytics-tab" tabIndex="0">
                    <UserAnalytics userId={userInfo.id} setMessage={setMessage} />
                </div>
            </div>
        </div>
    );
};

export default UserInfo;