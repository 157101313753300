import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import CategoriesService from './CategoriesService';
import Helper from '../common/Helper';

const CategoryEditor = ({ data, onUpdated }) => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        name: yup.string()
            .required('Поле обязательно для заполнения'),

    });

    const { register, handleSubmit, setValue, reset, watch, control, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: data && {
            name: data.name
        }
    });
    const handleUpdate = (formData) => {
        setMessage("");
        setSuccessMessage("");
        const func = data ? CategoriesService.update : CategoriesService.create;
        if (data) {
            formData["id"] = data.id;
        }
        func(formData).then((responce) => {
            setSuccessMessage("Рубрика успешно " + (data ? "обновлена" : "создана"));
            setInterval(() => {
                setSuccessMessage("");
            }, 5000);
            onUpdated();
            if (!data) {
                reset();
                navigate("/categories/");
            }

        },
            (error) => {
                setMessage(Helper.errorToString(error));
            });
    };
    useEffect(() => {
        if (data) {
            setValue("name", data.name);
        }
    }, [data]);

    return (<div>
        {data && <ul class="no-bullets">
            <li>id: {data.id}</li>
        </ul>}
        <form onSubmit={handleSubmit(handleUpdate)} autoComplete="off">
            <div className="form-group">
                <label>Название категории</label>
                <input role="presentation"
                    name="name"
                    type="text"
                    {...register('name')}
                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                />
                <div className="form-error">{errors.name?.message}</div>
            </div>
            <div className="mt-2 d-flex align-items-center justify-content-between">
                <button type="submit" className="btn btn-primary">
                    {data ? "Обновить" : "Создать"}
                </button>
            </div>
        </form>
        {message && (<div className="alert alert-danger mt-4">
            {message}
        </div>)}
        {successMessage && (<div className="alert alert-success mt-4">
            {successMessage}
        </div>)}
    </div>)
}

export default CategoryEditor