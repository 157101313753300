import React, { useState, useEffect } from 'react';
import './Questionnaire.css';
import FileUploader from '../common/FileUploader';
import Modal from '../common/Modal';
import ImagesDialog from '../common/ImagesDialog';
import ImagesService from '../common/ImagesService';

const IMAGE_URL = process.env.REACT_APP_API_BASE_STATIC_URL + "images/";

const Question = ({ question, register, index, setValue, onDelete, unregister }) => {
    const namePrefix = "questions[" + index + "]";
    const [message, setMessage] = useState('');

    const [answers, setAnswers] = useState('');

    const [image, setImage] = useState('');

    const [showImagesDialog, setShowImagesDialog] = useState(false);

    useEffect(() => {
        setAnswers(question.answers);
        if (question.image) {
            setImage(question.image);
        }
    }, [question.answers, question.image]);

    const uploadImage = (formData, filename) => {
        return ImagesService.uploadImage(formData).then((response) => {
            setImage(filename);
            setValue(namePrefix + "[image]", filename);
        });
    }

    const deleteAnswer = (e, index) => {
        e.preventDefault();

        if (answers.length == 1) {
            setMessage("У вопроса должен быть хотя бы один вариант ответа");
            setInterval(() => {
                setMessage("");
            }, 5000);
            return false;
        }


        unregister(namePrefix + "[answers][" + index + "]");

        if (index > -1) {
            answers.splice(index, 1);
        }
        console.log(answers);
        setAnswers([...answers]);
    };

    const onSelectImage = (filename) => {
        setShowImagesDialog(false);
        setImage(filename);
        setValue(namePrefix + "[image]", filename);
    }

    let questionRender = "";

    if (answers && answers.length > 0) {
        let answersRender = "";
        let addAnswer = (e) => { return false };

        if (answers[0].next) {//ветвление
            answersRender = answers.map((answer, answerIndex) => {
                return <div key={answer.text} className='row'>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label>Вариант ответа</label>
                            <input type="text"
                                name={namePrefix + "[answers][" + answerIndex + "][text]"}
                                {...register(namePrefix + '[answers][' + answerIndex + '][text]')}
                                className={`form-control`} />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label>Следующий</label>
                            <input role="presentation"
                                name={namePrefix + "[answers][" + answerIndex + "][next]"}
                                type="text"
                                {...register(namePrefix + '[answers][' + answerIndex + '][next]')}
                                className={`form-control`}
                            />
                        </div>
                    </div>
                </div>
            });
            addAnswer = (e) => {
                e.preventDefault();
                setAnswers(answers => [...answers, {
                    text: "Вариант ответа",
                    next: ""
                }]);

            };
        } else { //обычный выбор из списка
            answersRender = answers.map((answer, answerIndex) => {
                return <div key={answer.text + answerIndex} className="form-group d-flex align-items-center">
                    <label>Вариант ответа</label>
                    <input type="text"
                        name={namePrefix + "[answers][" + answerIndex + "][text]"}
                        {...register(namePrefix + '[answers][' + answerIndex + '][text]')}
                        className={`form-control`} />
                    <button onClick={(e) => deleteAnswer(e, answerIndex)} className='btn btn-outline-secondary ms-2'><em className="fa fa-trash-o"></em></button>
                </div>
            });

            addAnswer = (e) => {
                e.preventDefault();
                const newa = {
                    text: "Вариант ответа"
                };

                setValue(namePrefix + "[answers][" + answers.length + "]", newa);
                setAnswers(answers => [...answers, newa]);
            };
        }

        questionRender = <div className='row'>
            <div className='col-md-3 col-6'>
                <div className="form-group">
                    <label>ID вопроса</label>
                    <input role="presentation"
                        name={namePrefix + "[id]"}
                        type="text"
                        {...register(namePrefix + '[id]')}
                        className={`form-control`}
                    />
                </div>
            </div>
            <div className='col-md-4 col-6'>
                <div className="form-group">
                    <label>Вопрос</label>
                    <input role="presentation"
                        name={namePrefix + "[question]"}
                        type="text"
                        {...register(namePrefix + '[question]')}
                        className={`form-control`}
                    />
                </div>
            </div>
            <div className='col-md-4 col-6'>
                <div className="form-group">
                    <label>Следующий</label>
                    <input role="presentation"
                        name={namePrefix + "[next]"}
                        type="text"
                        {...register(namePrefix + '[next]')}
                        className={`form-control`}
                    />
                </div>
            </div>
            <div className='col-md-1 col-6'>
                <button className='btn btn-secondary mt-4' title="Удалить вопрос" onClick={onDelete}><em className='fa fa-trash-o'></em></button>
            </div>
            <div className='col-md-12'>
                <div className="form-group">
                    <label>Изображение</label>
                    <input
                        type="hidden" name={namePrefix + "[image]"}
                        {...register(namePrefix + '[image]')}
                    />
                    {image && <div className='image-container'>
                        <img src={IMAGE_URL + image} alt="" />
                        <div className='tools'>
                            <button onClick={(e) => setImage("")} className="btn btn-secondary">
                                <i className="fa fa-trash-o"></i>
                            </button>
                        </div>
                    </div>}
                    {!image && <div>
                        <div className="form-group d-flex align-items-center">
                            <label className='me-2'>Загрузить изображение</label>
                            <FileUploader uploadFunc={uploadImage} />
                            <button className='btn btn-primary'
                                onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowImagesDialog(!showImagesDialog) }}>
                                Выбрать из загруженных ранее
                            </button>
                            {/* <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas"
                                data-bs-target={"#offcanvasImagesDialog_" + index}
                                aria-controls={"#offcanvasImagesDialog_" + index} >Выбрать из загруженных ранее</button> */}
                            {showImagesDialog && <ImagesDialog show={true} onSelectImage={onSelectImage} id={"offcanvasImagesDialog_" + index} onClose={() => setShowImagesDialog(false)} />}
                        </div>
                    </div>}
                </div>
            </div>
            <div className='answers'>
                <h3>Ответы</h3><button onClick={addAnswer} className='btn btn-secondary'>+</button>
                {answersRender}
            </div>
        </div>

    } else { //TODO: refactor
        questionRender = <div className='row'>
            <div className='col-md-3 col-6'>
                <div className="form-group">
                    <label>ID вопроса</label>
                    <input role="presentation"
                        name={namePrefix + "[id]"}
                        type="text"
                        {...register(namePrefix + '[id]')}
                        className={`form-control`}
                    />
                </div>
            </div>
            <div className='col-md-4 col-6'>
                <div className="form-group">
                    <label>Вопрос</label>
                    <input role="presentation"
                        name={namePrefix + "[question]"}
                        type="text"
                        {...register(namePrefix + '[question]')}
                        className={`form-control`}
                    />
                </div>
            </div>
            <div className='col-md-4 col-6'>
                <div className="form-group">
                    <label>Следующий</label>
                    <input role="presentation"
                        name={namePrefix + "[next]"}
                        type="text"
                        {...register(namePrefix + '[next]')}
                        className={`form-control`}
                    />
                </div>
            </div>
            <div className='col-md-1 col-6'>
                <button title="Удалить вопрос" onClick={onDelete}><em className='fa fa-trash-o'></em>123</button>
            </div>
            <div className='col-md-12'>
                <div className="form-group">
                    <label>Изображение</label>
                    {image && <div className='image-container'>
                        <img src={IMAGE_URL + image} alt="" />
                        <div className='tools'>
                            <button onClick={(e) => setImage("")} className="btn btn-secondary">
                                <i className="fa fa-trash-o"></i>
                            </button>
                        </div>
                    </div>}
                    {!image && <div>
                        <div className="form-group d-flex align-items-center">
                            <label className='me-2'>Загрузить изображение</label>
                            <FileUploader uploadFunc={uploadImage} />
                        </div>
                        <button className='btn btn-primary'
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowImagesDialog(!showImagesDialog) }}>
                            Выбрать из загруженных ранее
                        </button>
                        {/* <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasImagesDialog" aria-controls="offcanvasRight">Выбрать из загруженных ранее</button> */}
                        {showImagesDialog && <ImagesDialog show={true} onSelectImage={onSelectImage} id={"offcanvasImagesDialog_" + index} onClose={() => setShowImagesDialog(false)} />}
                    </div>}
                </div>
            </div>
        </div>
    }
    // {
    //     showImagesDialog && <Modal show={true} title="Выбрать изображение" id={"imagesDialogModal" + index}>
    //         <ImagesDialog onSelectImage={onSelectImage} />
    //     </Modal>
    // }



    return (<div className='question-item'>
        {message && (<div className="alert alert-danger mt-4">
            {message}
        </div>)}
        {questionRender}
    </div>)
}

export default Question;