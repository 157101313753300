import React, { useState, useEffect } from 'react';
import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import { useNavigate, useParams } from "react-router-dom";
import './Posts.css';

import PostEditor from './PostEditor';
import { format, parseISO } from 'date-fns';

import post_thumb from './post_thumb.jpg';
import imagenotfound from './../common/imgnotfound.jpg';

import postStrings from './PostStrings';
import PostsService from './PostsService';
import Helper from '../common/Helper';
import CategoriesService from './CategoriesService';
import Strings from '../common/Strings';

import Toasts from '../common/Toasts';
import UsersService from '../users/UsersService';

const IMAGE_URL = process.env.REACT_APP_API_BASE_STATIC_URL + "images/";

const Posts = () => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [posts, setPosts] = useState('');
    const [groups, setGroups] = useState('');
    const [categories, setCategories] = useState('');
    const [statuses, setStatuses] = useState('');
    const [showNewModal, setShowNewModal] = useState(false);

    const [postCopy, setPostCopy] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(Helper.defaults.PAGE_SIZE);
    const [showMore, setShowMore] = useState(false);

    const navigate = useNavigate();
    const { postId } = useParams();

    const deletePost = (e, id) => {
        if (!window.confirm("Вы уверены, что хотите удалить публикацию?")) return;
        PostsService.del(id).then(
            (response) => {
                refresh();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    };

    const getPosts = () => {
        PostsService.get(1, currentPage * pageSize).then((response) => {
            setShowMore(response.length == pageSize);

            const all = [];

            if (postId && !response.find(x => x.id == postId)) {
                PostsService.getOne(postId).then((postResponse) => {
                    all.push(...response);
                    all.push(postResponse);
                    all.forEach(element => {
                        element.videos = element.videos && element.videos.length == 1 ? element.videos[0] : null;
                        element.files = element.files && element.files.length == 1 ? element.files[0] : null;
                    });
                    setPosts(all);
                });
            } else {
                all.push(...response);
                all.forEach(element => {
                    element.videos = element.videos && element.videos.length == 1 ? element.videos[0] : null;
                    element.files = element.files && element.files.length == 1 ? element.files[0] : null;
                });
                setPosts(all);
            }

        }, (error) => {
            setMessage(Helper.errorToString(error));
        });
    }

    const loadMore = () => {
        const page = currentPage ? currentPage + 1 : 1;
        PostsService.get(page, pageSize).then((response) => {
            const all = [];
            if (posts) {
                all.push(...posts);
            }
            if (response) {
                response.forEach(element => {
                    if (!all.find((x) => x.id == element.id)) {
                        all.push(element);
                    }
                });

            }

            all.forEach(element => {
                element.videos = element.videos && element.videos.length == 1 ? element.videos[0] : null;
                element.files = element.files && element.files.length == 1 ? element.files[0] : null;
            });
            setPosts(all);
            setCurrentPage(page);

            setShowMore(response.length == pageSize);

        });
    }

    const getCategories = () => {
        CategoriesService.get().then((response) => {
            console.log("CATEGORIES", response);
            setCategories(response);
        }, (error) => {
            setMessage(Helper.errorToString(error));
        });

    }

    const getGroups = () => {
        UsersService.getTelegramGroups().then((response) => {
            console.log("GROUPS", response);
            setGroups(response);
        }, (error) => {
            setMessage(Helper.errorToString(error));
        });
    }

    const publish = (id) => {
        PostsService.publish(id).then((response) => {
            refresh();
        }, (error) => {
            setMessage(Helper.errorToString(error));
        });
    }

    const enqueue = (id, publication_date) => {
        PostsService.enqueue(id, publication_date).then((response) => {
            refresh();
        }, (error) => {
            setMessage(Helper.errorToString(error));
        });
    }

    const createcopy = (id) => {
        const postToCopy = posts.find(x => x.id == id);
        if (postToCopy) {
            const postCopyData = structuredClone(postToCopy);
            delete postCopyData.id;
            delete postCopyData.creation_date;
            delete postCopyData.publication_date;
            postCopyData.status = "draft";
            setPostCopy(postCopyData);
            setShowNewModal(true);
        }
    }

    const refresh = () => {
        getPosts();
        getCategories();
        getGroups();
    }

    useEffect(() => {

        refresh();
        setStatuses(["draft", "queued"]);

    }, []);

    const postsRender = !posts ? "" : posts.map((post) => {

        const postCats = post.categories && post.categories.length > 0 ? post.categories.map((cat) => {
            const cats = post.categories.filter(x => x.id == cat);
            if (cats && cats.length == 1) {
                return cats[0].name;
            } else {
                return cat;
            }
        }

        ).join(', ')
            : "Без рубрики";

        const postGroups = post.group_chat_ids && post.group_chat_ids.length > 0 && groups.length > 0 ?
            post.group_chat_ids.map((group) => {
                const gr = groups.filter(x => x.chat_id == group);
                if (gr && gr.length == 1) {
                    return gr[0].title;
                } else {
                    return group;
                }
            }).join(', ')
            : (post.user_chat_ids && post.user_chat_ids.length > 0 ? "" : "Все группы");


        let mes = "";
        if (post.user_chat_ids && post.user_chat_ids.length > 0) {
            const len = post.user_chat_ids.length;
            if (len % 10 >= 2 && len % 10 <= 4) {
                mes = " пользователя";
            } else if (len % 10 == 1 && len != 11) {
                mes = " пользователь";
            } else {
                mes = " пользователей";
            }
        }

        const postUsers = post.user_chat_ids && post.user_chat_ids.length > 0 ? post.user_chat_ids.length + mes : "";

        const postImages = post.images && post.images.length > 0 ? post.images.map((image, idx) =>
            <img key={"img_" + idx} src={IMAGE_URL + image} alt="" onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = imagenotfound;
                return false;
            }} />)
            : "";

        return <div className='mb-4' key={post.id}>
            <div className="card card-post">

                <div className="card-body">
                    <div className="card-body-images">{postImages}</div>
                    <p className="card-text">{post.text}</p>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Рубрики: {postCats}</li>
                    <li className="list-group-item">Получатели: {postGroups}{postUsers ? ((postGroups ? ", " : "") + postUsers) : ""}</li>
                    <li className="list-group-item">Статус: {postStrings['status'][post.status]}</li>
                    {post.creation_date && <li className="list-group-item">Дата создания: {format(parseISO(post.creation_date), "dd.MM.yyyy HH:mm")}</li>}
                    {post.publication_date && <li className="list-group-item">Дата публикации: {format(parseISO(post.publication_date), "dd.MM.yyyy HH:mm")}</li>}
                    <li className="list-group-item d-flex">
                        <button className="btn btn-secondary" onClick={(e) => navigate("/posts/" + post.id)}><i className="fa fa-pencil"></i></button>
                        <button className="btn btn-secondary" onClick={(e) => createcopy(post.id)}><i className="fa fa-copy"></i></button>
                        {/* <button role="button" type="button" data-bs-toggle="modal" data-bs-target={"#modalUser" + user.id} aria-expanded="false" aria-controls={"#collapseUser" + user.id} className="btn btn-secondary"><i className="fa fa-pencil"></i></button> */}
                        <button onClick={(e) => deletePost(e, post.id)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>

                        {post.status == "draft" && post.publication_date && <button onClick={() => enqueue(post.id, post.publication_date)} className='btn btn-primary'>В очередь</button>}
                        {post.status != "published" && <button onClick={() => publish(post.id)} className='btn btn-primary'>Опубликовать сейчас</button>}
                    </li>
                </ul>
            </div>
            {
                postId && (post.id == postId) &&
                <Modal onClose={() => navigate("/posts")} show={true} title="Редактирование поста" id={"modalPost" + post.id}>
                    <PostEditor groups={groups} categories={categories} data={post} onUpdated={refresh} statuses={statuses} />
                </Modal>
            }
        </div >
    });

    return (<div>
        <div className="container-fluid">
            <MainTitle title="Публикации" />
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}
            <div className="control-panel">
                <button type="button" className="btn btn-primary nowrap" onClick={() => setShowNewModal(true)}>
                    Новая публикация
                </button>
                {/* <div className="input-group search-panel">
                    <input type="text" className="form-control" placeholder="Найти..."
                        aria-label="Найти..." aria-describedby="button-search" />
                    <button className="btn btn-outline-secondary" type="button" id="button-search">Найти</button>
                </div> */}
            </div>
            <div>
                {postsRender}
            </div>
            {showMore &&
                <div className='text-center'><button onClick={loadMore} className='btn btn-success'>Показать еще</button></div>}
            {showNewModal && <Modal show={true} title="Новая публикация" id="newPostModal" onClose={() => { setPostCopy(""); setShowNewModal(false); }}>
                <PostEditor groups={groups} categories={categories} data={postCopy} onUpdated={refresh} statuses={statuses} />
            </Modal>}
            <Toasts message={message} setMessage={setMessage} />
        </div>

    </div>)
}

export default Posts