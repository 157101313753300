import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Question from './Question';
import MainTitle from '../common/MainTitle';
import './Questionnaire.css';
import { useNavigate, useParams } from "react-router-dom";
import QuestionnairesService from './QuestionnairesService';
import Helper from '../common/Helper';
import Strings from '../common/Strings';

const QuestionnaireEditor = ({ groups, onUpdated }) => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const { questionaireId } = useParams();

    const [questionnaire, setQuestionnaire] = useState("");
    const [questions, setQuestions] = useState("");

    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        id: yup.string().required(Strings.validation.required),
        name: yup.string().required(Strings.validation.required),
        enabled: yup.bool(),
        start_tip: yup.string().required(Strings.validation.required),
        finish_tip: yup.string().required(Strings.validation.required),
        bonus: yup.number().required(Strings.validation.required),
        group_chat_ids: yup.array().of(yup.number()),
        type: yup.string().required(Strings.validation.required)
        //image: yup.string()
    });

    const groupOptions = !groups ? "" : groups.map((group) =>
        <option key={"gr_" + group.chat_id} value={group.chat_id}>{group.title}</option>
    );

    const { register, handleSubmit, reset, setValue, watch, unregister, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            enabled: true,
            start_tip: "Ответьте на все вопросы, это займет у вас не более 5 минут.",
            finish_tip: "Благодарим за прохождение опроса!",
            type: "quiz"
        }
    });

    const watchGroups = watch("group_chat_ids");

    const handleUpdate = (data) => {
        setMessage("");
        setSuccessMessage("");

        if(questions.length == 0) {
            setMessage("Нужно добавить хотя бы один вопрос");
            setInterval(() => {
                setMessage("");
            }, 5000);
            return false;
        }

        let result = structuredClone(data);

        result.questions = result.questions.reduce((questionObject, question) => {
            const id = question.id;
            delete question.id;
            questionObject[id] = question;
            return questionObject;
        }, {});
        //update result
        const func = questionaireId ? QuestionnairesService.update : QuestionnairesService.create;
        func(result).then(
            (response) => {
                setSuccessMessage("Викторина успешно " + (questionaireId ? "обновлена" : "создана"));
                setInterval(() => {
                    setSuccessMessage("");
                }, 5000);
                onUpdated();
                if (!questionaireId) {
                    reset();
                    setQuestions("");
                    // if(onCreated) {
                    //     onCreated();
                    // }
                    // navigate("/polls/" + result.id);
                }
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    };

    const addQuestion = (e) => {
        e.preventDefault();
        const newq = {
            id: "q-" + questions.length,
            next: "quit",
            question: "Вопрос",
            answers: [{
                text: "Вариант ответа"
            }],
            answers_in_row: 2
        };
        const qus = [...questions, newq]

        setValue("questions[" + questions.length + "]", newq);
        setQuestions(qus);
    };

    const deleteQuestion = (e, index) => {
        
        e.preventDefault();
        if(questions.length == 1) {
            setMessage("У викторины должен быть хотя бы один вопрос");
            setInterval(() => {
                setMessage("");
            }, 5000);
            return false;
        }

        unregister("questions[" + index + "]");
        if (index > -1) {
            questions.splice(index, 1);
        }
        console.log(questions);
        setQuestions([...questions]);
    };

    const getQuestionare = () => {
        if (questionaireId) {
            QuestionnairesService.getOne(questionaireId).then(
                (response) => {
                    console.log("QUEST", response);
                    response.questions = Object.entries(response.questions).map((question) => {
                        const qu = question[1];
                        qu.id = question[0];
                        return qu;
                    });

                    setQuestionnaire(response);
                    setQuestions(response.questions);

                    reset(response);
                    if (response.group_chat_ids && Array.isArray(response.group_chat_ids) && response.group_chat_ids.length > 0) {
                        setValue("group_chat_ids", response.group_chat_ids.map((x) => x.toString()));
                    }
                },
                (error) => {
                    setMessage(Helper.errorToString(error));
                }
            );
        } else {

        }

    }

    const dis = questionaireId ? { disabled: "disabled" } : {}; 

    useEffect(() => {
        getQuestionare();
    }, []);


    const questionsRender = !questions ? "Вопросов пока нет, добавьте первый вопрос" : questions.map((question, index) => {
        return <Question key={question.id} question={question} register={register} unregister={unregister} index={index} setValue={setValue} onDelete={(e) => deleteQuestion(e, index)} />
    })

    return (<div>

        <div className='container-fluid p-4'>

            <form onSubmit={handleSubmit(handleUpdate)} autoComplete="off">
                <input type="hidden" name="type" {...register('type')} />
                <div className="form-group">
                    <label>ID</label>
                    <input role="presentation"
                        {...dis}
                        name="id"
                        type="text"
                        {...register('id')}
                        className={`form-control ${errors.id ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.id?.message}</div>
                </div>
                <div className="form-group">
                    <label>Название опроса</label>
                    <input role="presentation"
                        name="name"
                        type="text"
                        {...register('name')}
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.name?.message}</div>
                </div>
                <div className="form-group">
                    <label>Сообщение перед опросом</label>
                    <input role="presentation"
                        name="start_tip"
                        type="text"
                        {...register('start_tip')}
                        className={`form-control ${errors.start_tip ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.start_tip?.message}</div>
                </div>
                <div className="form-group">
                    <label>Сообщение после опроса</label>
                    <input role="presentation"
                        name="finish_tip"
                        type="text"
                        {...register('finish_tip')}
                        className={`form-control ${errors.finish_tip ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.finish_tip?.message}</div>
                </div>
                <div className="form-group">
                    <label>Вознаграждение за прохождение опроса</label>
                    <input role="presentation"
                        name="bonus"
                        type="text"
                        {...register('bonus')}
                        className={`form-control ${errors.bonus ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.finish_tip?.message}</div>
                </div>
                <div className="form-group">
                    <label>Группы</label>{watchGroups && watchGroups.length ? <button onClick={() => setValue("group_chat_ids", [])} type='button' className='ms-3 mb-2 btn btn-sm btn-outline-secondary'>Все группы</button> : ""}
                    <select name="group_chat_ids"
                        multiple={true}
                        {...register('group_chat_ids')}
                        className="form-control"
                    >
                        {groupOptions}
                    </select>
                </div>
                <div className="form-check d-none">
                    <input className="form-check-input "
                        disabled="disabled"
                        id="enabledCheck"
                        name="enabled"
                        type="checkbox"
                        {...register('enabled')}
                    />
                    <label htmlFor="enabledCheck">Включен</label>
                </div>
                <div className='questions'>
                    <h2>Вопросы</h2>
                    <p>Первый вопрос должен быть с идентификатором root. У завершающих вопросов в поле "Следующий вопрос" нужно указать quit. </p>
                    {questionsRender}
                </div>



                <div className="mt-2 d-flex align-items-center justify-content-between">
                    <button onClick={addQuestion} type="button" className="btn btn-secondary">
                        Добавить вопрос
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Сохранить
                    </button>
                </div>
            </form>
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}
        </div>
    </div>)
}

export default QuestionnaireEditor