import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { format, parseISO } from 'date-fns'
import { useNavigate, useParams } from "react-router-dom";

import AsyncSelect from 'react-select/async';

import postStrings from './PostStrings';
import FileUploader from '../common/FileUploader';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import post_thumb from './post_thumb.jpg';
import imagenotfound from './../common/imgnotfound.jpg';

import PostsService from './PostsService';

import Helper from '../common/Helper';
import ImagesService from '../common/ImagesService';

import ImagesDialog from '../common/ImagesDialog';
import UsersService from '../users/UsersService';

import Comments from '../comments/Comments';

const IMAGE_URL = process.env.REACT_APP_API_BASE_STATIC_URL + "images/";

const PostEditor = ({ onUpdated, data, categories, groups, statuses }) => {

    const MAX_IMAGES = 10;

    const [successMessage, setSuccessMessage] = useState('');
    const [message, setMessage] = useState('');
    const [images, setImages] = useState([]);
    const [showImagesDialog, setShowImagesDialog] = useState(false);

    const navigate = useNavigate();

    const groupOptions = !groups ? "" : groups.map((group) =>
        <option key={"gr_" + group.chat_id} value={group.chat_id}>{group.title}</option>
    );

    const categoryOptions = !categories ? "" : categories.map((category) =>
        <option key={"cat_" + category.id} value={category.id}>{category.name}</option>
    );

    const statusOptions = !statuses ? "" : statuses.map((st) =>
        <option key={"stat_" + st} value={st}>{postStrings['status'][st]}</option>
    );

    // id: Optional[int]
    // text: Optional[str]
    // images: Optional[list]
    // videos: Optional[list]
    // file: Optional[list]
    // category: Optional[list]
    // group_chat_ids: Optional[list]
    // user_chat_ids: Optional[list]
    // publication_date: Optional[str]
    // creation_date: Optional[str]
    // comments: Optional[list]
    // status: Optional[str]

    const dis = data && data.status == 'published' ? { disabled: "disabled" } : {};

    const validationSchema = yup.object().shape({
        text: yup.string(),
        status: yup.string("draft"),
        categories: yup.array().of(yup.number()),
        group_chat_ids: yup.array().of(yup.number()),
    });

    const { register, handleSubmit, setValue, reset, watch, control, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            status: "draft"
        }
    });

    const watchText = watch("text");
    const watchGroups = watch("group_chat_ids");

    useEffect(() => {
        if (data) {
            Object.entries(data).forEach((dataItem) => {
                if (dataItem[1] && Array.isArray(dataItem[1]) && dataItem[1].length > 0 && dataItem[0] != "user_chat_ids") {
                    setValue(dataItem[0], dataItem[1].map((x) => x.toString()));
                } else {
                    setValue(dataItem[0], dataItem[1]);
                }
            });
            if (data.images) {
                setImages(data.images);
            }
        }
    }, [data]);

    const handleUpdate = (formData) => {
        console.log(formData);

        setMessage("");
        setSuccessMessage("");

        formData.videos = formData.videos ? [formData.videos] : null;
        formData.files = formData.files ? [formData.files] : null;
        const func = data.id ? PostsService.update : PostsService.create;
        func(formData).then((response) => {
            setSuccessMessage("Публикация успешно " + (data.id ? "обновлена" : "создана"));
            setInterval(() => {
                setSuccessMessage("");
            }, 5000);
            onUpdated();
            if (!data) {
                navigate("/posts/");
                reset();
            }
        },
            (error) => {
                setMessage(Helper.errorToString(error));
            });
    };

    const deleteImage = (e, image) => {
        let imgs = images.filter((x) => x != image);
        setValue("images", imgs);
        setImages(imgs);
    }

    const uploadImage = (formData, filename) => {
        return ImagesService.uploadImage(formData).then((response) => {
            const imgs = [...images, filename];
            setValue("images", imgs);
            setImages(imgs);
        });
    }

    const onSelectImage = (filename) => {
        setShowImagesDialog(false);
        const imgs = [...images, filename];
        setValue("images", imgs);
        setImages(imgs);
    }

    const loadUsers = (inputValue, callback) => {
        UsersService.getUsers(1, 10, inputValue).then((response) => {
            if (response.length > 0) {
                //         "phone": "79037848847",
                // "name": "Katerina Semenova",
                // "groups": [
                //     "админ"
                // ],
                // "email": "support@synweb.ru",
                // "disabled": false,
                // "first_name": "Katerina",
                // "last_name": "Semenova",
                // "user_chat_id": 66491948,
                // "username": "ro_umka",
                const opts = response.map((x) => { return { value: x.user_chat_id, label: ((x.username ? (x.username + " | ") : "") + x.phone + " | " + x.name) } });
                callback(opts);
            }
        });
        // setTimeout(() => {
        //   callback(filterColors(inputValue));
        // }, 1000);
    };

    //const imagesRender = images && images.length > 0 ? images.map((image) => {
    const imagesRender = images ? images.map((image, idx) => {
        const imgsrc = IMAGE_URL + image;
        return <div key={image + idx} className='post-image image-item'>
            <img src={imgsrc} alt="" onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = imagenotfound;
                return false;
            }} />
            {!data || data.status != "published" && <div className='tools'>
                <button onClick={(e) => deleteImage(e, image)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
            </div>}
        </div>
    }) : "";
    // {
    // id: Optional[int]
    // text: Optional[str]
    // images: Optional[list]
    // videos: Optional[list]
    // files: Optional[list]
    // categories: Optional[list]
    // group_chat_ids: Optional[list]
    // user_chat_ids: Optional[list]
    // publication_date: Optional[str]
    // creation_date: Optional[str]
    // comments: Optional[list]
    // status: Optional[str]
    // }
    return (
        <div className="">
            {data.id && <ul className="no-bullets">
                <li>id: {data.id}</li>
                <li>Дата создания: {format(parseISO(data.creation_date), "dd.MM.yyyy HH:mm")}</li>
            </ul>}
            <p>Изображения ({images.length})</p>
            <div className='post-images-editor images-editor'>
                {imagesRender}
            </div>
            {(!data || data.status != "published") && images.length < MAX_IMAGES && <div className="form-group d-flex align-items-center">
                <label className='me-2'>Загрузить изображение</label>
                <FileUploader uploadFunc={uploadImage} />
                <button className='btn btn-primary'
                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowImagesDialog(!showImagesDialog) }}>
                    Выбрать из загруженных ранее
                </button>
                {/* <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasImagesDialog" aria-controls="offcanvasRight">Выбрать из загруженных ранее</button> */}
            </div>}
            <form onSubmit={handleSubmit(handleUpdate)} autoComplete="off">
                <div className="form-group">
                    <label>Текст поста ({watchText ? watchText.length : 0})</label>
                    <textarea rows="10" role="presentation" {...dis}
                        {...register('text')}
                        className={`form-control ${errors.text ? 'is-invalid' : ''}`}
                    ></textarea>
                    <div className="form-error">{errors.text?.message}</div>
                </div>
                <div className="form-group">
                    <label>ID видео</label>
                    <input role="presentation" {...dis}
                        name="videos"
                        type="text"
                        {...register('videos')}
                        className={`form-control ${errors.videos ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.videos?.message}</div>
                </div>
                <div className="form-group">
                    <label>ID файла</label>
                    <input role="presentation"
                        {...dis}
                        name="files"
                        type="text"
                        {...register('files')}
                        className={`form-control ${errors.files ? 'is-invalid' : ''}`}
                    />
                    <div className="form-error">{errors.files?.message}</div>
                </div>
                <div className="form-group">
                    <label>Дата публикации</label>
                    <Controller
                        control={control}
                        name="publication_date"
                        render={({ field }) => (
                            <DatePicker
                                {...dis}
                                className={`form-control ${errors.publication_date ? 'is-invalid' : ''}`}
                                dateFormat="dd.MM.yyyy HH:mm"
                                showTimeSelect
                                //locale="ru"
                                placeholderText="Выберите дату"
                                onChange={(date) => { field.onChange(format(date, "yyyy-MM-dd HH:mm")) }}
                                selected={field.value && new Date(field.value)}
                            />
                        )}
                    />
                </div>
                {data && data.status != "draft" && <div className="form-group">
                    <label>Статус</label>
                    <select name="status"
                        {...dis}
                        {...register('status')}
                        className="form-control"
                    >
                        {statusOptions}
                        {data && data.status == "published" ? <option value="published">Опубликовано</option> : ""}
                    </select>
                </div>}
                <div className="form-group">
                    <label>Группы</label>{watchGroups && watchGroups.length ? <button onClick={() => setValue("group_chat_ids", [])} type='button' className='ms-3 mb-2 btn btn-sm btn-outline-secondary'>Все группы</button> : ""}
                    <select name="group_chat_ids"
                        {...dis}
                        multiple={true}
                        {...register('group_chat_ids')}
                        className="form-control"
                    >
                        {groupOptions}
                    </select>
                </div>
                <div className="form-group">
                    <label>Пользователи</label>
                    <Controller
                        control={control}
                        name="user_chat_ids"
                        render={({ field }) => (
                            <AsyncSelect
                                {...dis}
                                className={`form-control ${errors.publication_date ? 'is-invalid' : ''}`}
                                dateFormat="dd.MM.yyyy HH:mm"
                                cacheOptions
                                onChange={(val) => { field.onChange(val.map((x) => { return { user_chat_id: parseInt(x.value), name: x.label } })) }}
                                value={field.value ? field.value.map((y) => { return { value: y.user_chat_id, label: y.name } }) : ""}
                                loadOptions={loadUsers}
                                closeMenuOnSelect={false}
                                noOptionsMessage={() => "Ничего не найдено"}
                                placeholder={"Начните вводить имя пользователя или телефон..."}
                                isMulti />
                        )}
                    />
                </div>

                <div className="form-group">
                    <label>Категории</label>
                    <select name="categories"
                        {...dis}
                        multiple={true}
                        {...register('categories')}
                        className="form-control"
                    >
                        {categoryOptions}
                    </select>
                </div>

                <div className="mt-2 d-flex align-items-center justify-content-between">
                    <button type="submit" className="btn btn-primary"
                        {...dis}
                    >
                        {data.id ? "Обновить" : "Создать"}
                    </button>
                </div>
            </form>
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}
            {/* 
            {showImagesDialog && <Modal show={true} title="Выбрать изображение" id="imagesDialogModal">
                <ImagesDialog onSelectImage={onSelectImage} />
            </Modal>} */}

            {showImagesDialog && <ImagesDialog show={true} onSelectImage={onSelectImage} id="offcanvasImagesDialog" onClose={() => setShowImagesDialog(false)} />}

            {data && <Comments publicationId={data.id} />}
        </div>
    );
}

export default PostEditor