import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";

import { format, parseISO } from 'date-fns';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import Helper from '../common/Helper';
import Strings from '../common/Strings';
import Toasts from '../common/Toasts';

import './Hashtags.css';

import HashtagsStrings from './HashtagsStrings';
import HashtagsService from './HashtagsService';


const HashtagEditor = ({ data, onUpdated }) => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        text: yup.string(),
        status: yup.string("draft"),
        categories: yup.array().of(yup.number()),
        group_chat_ids: yup.array().of(yup.number()),
    });

    const { register, handleSubmit, setValue, reset, watch, control, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            enabled: true,
            hashtag_string: "#"
        }
    });

    useEffect(() => {
        if (data) {
            if (data) {
                Object.entries(data).forEach((dataItem) => {
                    setValue(dataItem[0], dataItem[1]);
                });
            }
        }
    }, [data]);

    // id: Optional[int]
    // hashtag_string: str
    // publication_reward: int
    // reply_reward: int
    // description: Optional[str]
    // creation_date: Optional[str]
    // enabled: bool

    const handleUpdate = (formData) => {
        setMessage("");
        setSuccessMessage("");
        formData.publication_reward = 0;
        const func = data ? HashtagsService.update : HashtagsService.create;
        func(formData).then((response) => {
            setSuccessMessage("Хэштег успешно " + (data ? "обновлен" : "создан"));
            setInterval(() => {
                setSuccessMessage("");
            }, 5000);
            onUpdated();
            if (!data) {
                navigate("/hashtags/");
                reset();
            }
        },
            (error) => {
                setMessage(Helper.errorToString(error));
            });
    };

    return (<div>
        {data && <ul className="no-bullets">
            <li>id: {data.id}</li>
            <li>Дата создания: {format(parseISO(data.creation_date), "dd.MM.yyyy HH:mm")}</li>
        </ul>}

        <form onSubmit={handleSubmit(handleUpdate)} autoComplete="off">
            <div className="form-group">
                <label>Хэштег</label>
                <input role="presentation"
                    name="hashtag_string"
                    type="text"
                    {...register('hashtag_string')}
                    className={`form-control ${errors.hashtag_string ? 'is-invalid' : ''}`}
                />
                <div className="form-error">{errors.hashtag_string?.message}</div>
            </div>
            <div className="form-group">
                <label>Вознаграждение за ответ</label>
                <input role="presentation"
                    name="reply_reward"
                    type="number"
                    {...register('reply_reward')}
                    className={`form-control ${errors.reply_reward ? 'is-invalid' : ''}`}
                />
                <div className="form-error">{errors.reply_reward?.message}</div>
            </div>
            <div className="form-group">
                <label>Описание</label>
                <textarea rows="3" role="presentation"
                    {...register('description')}
                    className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                ></textarea>
                <div className="form-error">{errors.description?.message}</div>
            </div>
            
            <div className="mt-2 d-flex align-items-center justify-content-between">
                <button type="submit" className="btn btn-primary">
                    {data ? "Обновить" : "Создать"}
                </button>
            </div>
        </form>
        {message && (<div className="alert alert-danger mt-4">
            {message}
        </div>)}
        {successMessage && (<div className="alert alert-success mt-4">
            {successMessage}
        </div>)}
    </div>)
}

export default HashtagEditor