import React, { useState, useEffect } from 'react';
import MainTitle from '../common/MainTitle';
import './Questionnaire.css';
import { useNavigate, useParams } from "react-router-dom";
import Modal from '../common/Modal';
import QuestionnaireEditor from './QuestionnaireEditor';
import QuestionnairesService from './QuestionnairesService';
import Helper from '../common/Helper';
import Toasts from '../common/Toasts';
import UsersService from '../users/UsersService';

const Questionnaires = () => {
    const navigate = useNavigate();

    const { questionaireId } = useParams();

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(Helper.defaults.PAGE_SIZE);
    const [showMore, setShowMore] = useState(false);

    const [groups, setGroups] = useState('');

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState("");
    const [questionnaires, setQuestionnaires] = useState('');

    const [showNewModal, setShowNewModal] = useState(false);

    const deleteQuestionnaire = (e, id) => {
        QuestionnairesService.del(id).then(
            (response) => {
                const qus = questionnaires.filter((qu) => qu.id != id);
                setQuestionnaires(qus);
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const getQuestionnaires = () => {

        QuestionnairesService.get(currentPage, currentPage * pageSize).then(
            (response) => {
                setShowMore(response.length == pageSize);
                // response.forEach(element => {
                //     element.id = element._id;
                // });
                setQuestionnaires(response);
                setShowMore(response.length == pageSize);
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const loadMore = () => {
        const page = currentPage ? currentPage + 1 : 1;
        QuestionnairesService.get(page, pageSize).then((response) => {
            const all = [];
            if (questionnaires) {
                all.push(...questionnaires);
            }
            if (response) {
                all.push(...response);
            }
            setQuestionnaires(all);
            setCurrentPage(page);

            setShowMore(response.length == pageSize);

        });
    }

    const refresh = () => {
        getQuestionnaires();
        getGroups();
    }

    const start = (id) => {
        QuestionnairesService.start(id).then((response) => {
            refresh();
            alert("Викторина запущена");
        },
            (error) => {
                setMessage(Helper.errorToString(error));
            });
    }

    const getGroups = () => {
        UsersService.getTelegramGroups().then((response) => {
            console.log("GROUPS", response);
            setGroups(response);
        }, (error) => {
            setMessage(Helper.errorToString(error));
        });
    }

    useEffect(() => {
        refresh();
    }, []);

    const questionnairesRender = !questionnaires ? "" : questionnaires.map((qu) => {
        //const quGroups = qu.groups && qu.length > 0 ? qu.groups.map((group) => group.name).join(', ') : "Все группы";


        const quGroups = qu.group_chat_ids && qu.group_chat_ids.length > 0 && groups.length > 0 ?
            qu.group_chat_ids.map((group) => {
                const gr = groups.filter(x => x.chat_id == group);
                if (gr && gr.length == 1) {
                    return gr[0].title;
                } else {
                    return group;
                }
            }).join(', ')
            : "Все группы";


        return <div key={qu.id} className="card card-questionnaire mb-4">
            <div className="card-body">
                <h5 className="card-title">{qu.id} - {qu.name}</h5>
                <p className="card-text">{qu.start_tip}</p>
                <p className="card-text">{qu.finish_tip}</p>
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">Вознаграждение: {qu.bonus} баллов</li>
                <li className="list-group-item">Группы: {quGroups}</li>
                {/* <li className="list-group-item">Статус: {qu.enabled ? "Запущена" : "Выключена"}</li> */}
                <li className="list-group-item">Всего участников: {qu.participants_count}</li>
                <li className="list-group-item d-flex">
                    <button className="btn btn-secondary" onClick={(e) => navigate("/polls/" + qu.id)}><i className="fa fa-pencil"></i></button>
                    {/* <button role="button" type="button" data-bs-toggle="modal" data-bs-target={"#modalUser" + user.id} aria-expanded="false" aria-controls={"#collapseUser" + user.id} className="btn btn-secondary"><i className="fa fa-pencil"></i></button> */}
                    <button onClick={(e) => deleteQuestionnaire(e, qu.id)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
                    <button onClick={() => start(qu.id)} className='btn btn-primary'>Запустить викторину</button>
                </li>
            </ul>
            {questionaireId && (qu.id == questionaireId) &&
                <Modal onClose={(e) => { navigate("/polls"); }} show={true} title="Редактирование викторины" id={"modalQuestionaire" + qu.id}>
                    <QuestionnaireEditor groups={groups} onUpdated={refresh}/>
                </Modal>}
        </div>
    });

    return (<div className='container-fluid'>
        <MainTitle title="Викторины" />
        {message && (<div className="alert alert-danger mt-4">
            {message}
        </div>)}
        {successMessage && (<div className="alert alert-success mt-4">
            {successMessage}
        </div>)}
        <div className="control-panel">
            <button type="button" className="btn btn-primary nowrap" onClick={() => setShowNewModal(true)}>
                Новая викторина
            </button>
            {/* <div className="input-group search-panel">
                    <input type="text" className="form-control" placeholder="Найти..."
                        aria-label="Найти..." aria-describedby="button-search" />
                    <button className="btn btn-outline-secondary" type="button" id="button-search">Найти</button>
                </div> */}
        </div>
        <div className=''>
            {questionnairesRender}
            {showMore &&
                <div className='text-center'><button onClick={loadMore} className='btn btn-success'>Показать еще</button></div>}
        </div>
        <Modal show={showNewModal} onClose={(e) => { setShowNewModal(false); }} title="Новая викторина" id="newModal">
            <QuestionnaireEditor groups={groups} onUpdated={refresh}/>
        </Modal>
        <Toasts message={message} setMessage={setMessage} />
    </div>)
}

export default Questionnaires