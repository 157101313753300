import React, { useState, useEffect } from 'react';
import MainTitle from '../common/MainTitle';
import './Incidents.css';
import Toasts from '../common/Toasts';
import { format, parseISO } from 'date-fns';

import StopWordsService from './StopWordsService';

import Helper from '../common/Helper';

const Incidents = ({ userId, inline }) => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState("");

    const [incidents, setIncidents] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(Helper.defaults.PAGE_SIZE);
    
    const [showMore, setShowMore] = useState(false);

    // {
    //     "creation_date": "2023-05-30T14:50:07.948000",
    //     "user_chat_id": 50772230,
    //     "type": "link",
    //     "substring": "https://music.yandex.ru/album/697151/track/6424282",
    //     "message_text": "ссылка https://music.yandex.ru/album/697151/track/6424282 ссылка"
    // },

    const getIncidents = () => {
        if (userId) {
            StopWordsService.getIncidentsForUser(userId).then((response) => {
                //console.log(response);
                setIncidents(response);
            });
        } else {
            StopWordsService.getIncidents(1, currentPage * pageSize).then((response) => {
                //console.log(response);
                setShowMore(response.length == pageSize);
                setIncidents(response);
            });
        }
    }

    const loadMore = () => {
        const page = currentPage ? currentPage + 1 : 1;
        StopWordsService.getIncidents(page, currentPage * pageSize).then((response) => {
            const all = [];
            if (incidents) {
                all.push(...incidents);
            }
            all.push(...response);

            setIncidents(all);
            setCurrentPage(page);

            setShowMore(response.length == pageSize);

        });
    }

    const deleteIncident = (e, id) => {

    }

    useEffect(() => {
        getIncidents();
    }, []);

    const incidentsRender = !incidents || incidents.length == 0 ? "Инцидентов нет" : incidents.map((incident) => {

        const text = incident.message_text.replace(incident.substring, "<span>" + incident.substring + "</span>")

        return <div className='mb-4' key={incident.id}>
            <div className="card card-incident">
                {/* <img src="..." className="card-img-top" alt="..."> */}
                <div className="card-body">
                    <p className="card-text" dangerouslySetInnerHTML={{ __html: text }}></p>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Стоп-слово: {incident.substring}</li>
                    <li className="list-group-item">Дата сообщения: {format(parseISO(incident.creation_date), "dd.MM.yyyy HH:mm")}</li>
                    {
                        inline &&
                        <li className="list-group-item d-flex">Пользователь: {incident.user.name} &nbsp; {incident.user.username && <a target="_blank" href={"https://t.me/" + incident.user.username}>@{incident.user.username}</a>}
                            <a href={"/users/" + incident.user.id} target='blank' className="btn btn-outline-secondary btn-sm ms-auto"><i className="fa fa-pencil"></i></a>
                        </li>
                    }
                    {/* <li className="list-group-item">Телефон: {incident.user.phone}</li>
                    <li className="list-group-item">Юзернейм: {incident.user.telegramUsername}</li>
                    <li className="list-group-item">Email: {incident.user.email}</li>*/}
                    <li className="list-group-item">Статус пользователя: {incident.user.disabled ? "Отключен" : "Включен"}</li>

                    <li className="list-group-item d-flex">
                        {/* <button className="btn btn-secondary" onClick={(e) => navigate("/incidents/" + incident.id)}><i className="fa fa-pencil"></i></button> */}
                        {/* <button role="button" type="button" data-bs-toggle="modal" data-bs-target={"#modalUser" + user.id} aria-expanded="false" aria-controls={"#collapseUser" + user.id} className="btn btn-secondary"><i className="fa fa-pencil"></i></button> */}
                        {/* <button onClick={(e) => deleteIncident(e, incident.id)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button> */}
                    </li>
                </ul>
            </div>
            {/* {incidentId && (incident.id == incidentId) &&
                <Modal onClose={() => navigate("/incidents")} show={true} title="Редактирование поста" id={"modalincident" + incident.id}>
                    <incidentEditor groups={groups} categories={categories} data={incident} onUpdated={refresh} statuses={statuses} />
                </Modal>} */}
        </div>
    });

    return (<div>
        <div className="container-fluid">
            {!inline && <MainTitle title="Удалённые сообщения" />}
            <div className="control-panel">

            </div>

            <div>
                {incidentsRender}
            </div>
            {showMore &&
                <div className='text-center'><button onClick={loadMore} className='btn btn-success'>Показать еще</button></div>}
            <Toasts message={message} setMessage={setMessage} />
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}
        </div>
    </div>)

    return (<div>

    </div>)
}

export default Incidents