import MainTitle from "./common/MainTitle";


function NotFoundPage() {

    return <div>
        <MainTitle title="404 - Страница не найдена"/>
    </div>
}

export default NotFoundPage;