import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import Helper from '../common/Helper';
import Strings from '../common/Strings';
import Toasts from '../common/Toasts';

import './Hashtags.css';

import HashtagsStrings from './HashtagsStrings';
import HashtagsService from './HashtagsService';
import HashtagEditor from './HashtagEditor';

const Hashtags = () => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showNewModal, setShowNewModal] = useState(false);

    const navigate = useNavigate();
    const { hashtagId } = useParams();

    const [hashtags, setHashtags] = useState("");

    const getHashtags = () => {
        HashtagsService.get().then(
            (response) => {
                console.log("HASHTAGS", response);
                setHashtags(response);
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const deleteHashtag = (e, id) => {
        if (!window.confirm("Вы уверены, что хотите удалить хэштег?")) return;
        HashtagsService.del(id).then(
            (response) => {
                refresh();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const refresh = () => {
        getHashtags();
    }

    useEffect(() => {
        refresh();
    }, []);

    // id: Optional[int]
    // hashtag_string: str
    // publication_reward: int
    // reply_reward: int
    // description: Optional[str]
    // creation_date: Optional[str]
    // enabled: bool

    const hashtagsRender = !hashtags ? "" : hashtags.map((hashtag) => {

        return <div key={hashtag.id} className="card card-hashtag mb-4">
            <div className="card-body">
                <h5 className="card-title">{hashtag.id} - {hashtag.hashtag_string}</h5>
                <p className="card-text">{hashtag.description}</p>
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">Вознаграждение: {hashtag.reply_reward} баллов</li>
                <li className="list-group-item">Статус: {hashtag.enabled ? "Активен" : "Выключен"}</li>
                <li className="list-group-item d-flex">
                    <button className="btn btn-secondary" onClick={(e) => navigate("/hashtags/" + hashtag.id)}><i className="fa fa-pencil"></i></button>
                    {/* <button role="button" type="button" data-bs-toggle="modal" data-bs-target={"#modalUser" + user.id} aria-expanded="false" aria-controls={"#collapseUser" + user.id} className="btn btn-secondary"><i className="fa fa-pencil"></i></button> */}
                    <button onClick={(e) => deleteHashtag(e, hashtag.id)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
                </li>
            </ul>
            {hashtagId && (hashtag.id == hashtagId) &&
                <Modal onClose={(e) => { navigate("/hashtags"); refresh(); }} show={true} title="Редактирование хэштега" id={"modal" + hashtag.id}>
                    <HashtagEditor data={hashtag}  onUpdated={refresh}/>
                </Modal>}
        </div>
    });

    return (<div>
        <div className="container-fluid">
            <MainTitle title="Хэштеги" />
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}
            <div className="control-panel">
                <button type="button" className="btn btn-primary nowrap" onClick={() => setShowNewModal(true)}>
                    Новый хэштег
                </button>
                {/* <div className="input-group search-panel">
                    <input type="text" className="form-control" placeholder="Найти..."
                        aria-label="Найти..." aria-describedby="button-search" />
                    <button className="btn btn-outline-secondary" type="button" id="button-search">Найти</button>
                </div> */}
            </div>

            <div>
                {hashtagsRender}
            </div>
            {showNewModal && <Modal show={true} title="Новый хэштег" id="newModal" onClose={() => setShowNewModal(false)}>
                <HashtagEditor onUpdated={refresh}/>
            </Modal>}
            <Toasts message={message} setMessage={setMessage} />
        </div>
    </div>)
}

export default Hashtags