import axios from 'axios';
import AuthService from '../auth/AuthService';
import originHeaders from '../auth/AuthService';

const API_URL = process.env.REACT_APP_API_BASE_URL;

//done
const getIncidents = (start_date, end_date) => {
    return axios.get(API_URL + 'analytics/incidents?start_date=' + start_date + "&end_date=" + end_date, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

//done
const getChatSubscribersCount = () => {
    return axios.get(API_URL + 'analytics/chats/subscribers', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

//done
const getChatSubscription = (start_date, end_date) => {
    return axios.get(API_URL + 'analytics/chats/subscription?start_date=' + start_date + "&end_date=" + end_date, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

//done
const getActiveUsersCount = (start_date, end_date) => {
    return axios.get(API_URL + 'analytics/chats/active_users?start_date=' + start_date + "&end_date=" + end_date, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

//done
const getReactionsStat = (start_date, end_date) => {
    return axios.get(API_URL + 'analytics/chats/reactions?start_date=' + start_date + "&end_date=" + end_date, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

//done, error in api
const getHashtagsStat = (start_date, end_date) => {
    return axios.get(API_URL + 'analytics/chats/hashtags?start_date=' + start_date + "&end_date=" + end_date, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

//done
const getMessagesCountStat = (start_date, end_date) => {
    return axios.get(API_URL + 'analytics/chats/messages?start_date=' + start_date + "&end_date=" + end_date, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}


const getUserSubscriptionStat = (start_date, end_date) => {
    return axios.get(API_URL + 'analytics/user/subscription?start_date=' + start_date + "&end_date=" + end_date, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

const getChatTop5Stat = () => {
    return axios.get(API_URL + 'analytics/chats/rating', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

//done
const getChatIncidentsStat = (start_date, end_date) => {
    return axios.get(API_URL + 'analytics/chat_moderation/deleted_messages?start_date=' + start_date + "&end_date=" + end_date, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

//done
const getBlockedUsersStat = (start_date, end_date) => {
    return axios.get(API_URL + 'analytics/chat_moderation/delete_users?start_date=' + start_date + "&end_date=" + end_date, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

//done
const getLikesForPostsStat = (start_date, end_date) => {
    return axios.get(API_URL + 'analytics/additional_analytics/likes_for_post?start_date=' + start_date + "&end_date=" + end_date, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

//done
const getUserSubscription = (id) => {
    return axios.get(API_URL + 'analytics/user/subscription/' + id, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

//done
const getUserReactions = (id, start_date, end_date) => {
    return axios.get(API_URL + 'analytics/user/reactions/' + id + '?start_date=' + start_date + "&end_date=" + end_date, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

//done
const getUserHashtags = (id, start_date, end_date) => {
    return axios.get(API_URL + 'analytics/user/hashtags/' + id + '?start_date=' + start_date + "&end_date=" + end_date, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

//done
const getUserReferals = (id) => {
    return axios.get(API_URL + 'analytics/user/referal/' + id, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

const getUserTransactions = (id, start_date, end_date) => {
    return axios.get(API_URL + 'analytics/user/transactions/' + id + '?start_date=' + start_date + "&end_date=" + end_date, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

const AnalyticsService = {
    getIncidents,
    getChatSubscribersCount,
    getChatSubscription,
    getActiveUsersCount,
    getReactionsStat,
    getHashtagsStat,
    getMessagesCountStat,
    getChatTop5Stat,
    getChatIncidentsStat,
    getBlockedUsersStat,
    getLikesForPostsStat,

    getUserSubscription,
    getUserReactions,
    getUserHashtags,
    getUserReferals,
    getUserTransactions,
    getUserSubscriptionStat,

}

export default AnalyticsService;