import React, { useState, useRef, useEffect } from "react";
import Helper from "./Helper";

const FileUploader = ({ uploadFunc }) => {

    const [file, setFile] = useState(''); // storing the uploaded file    
    // storing the recived file from backend
    //const [data, getFile] = useState({ name: "", path: "" });
    const [progress, setProgess] = useState(0); // progess bar
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const el = useRef(); // accesing input element
    const handleChange = (e) => {
        setProgess(0)
        const file = e.target.files[0]; // accesing file
        console.log(file);
        setFile(file); // storing file
        // setTimeout(uploadFile, 1000);

    }
    const uploadFile = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!file) {
            el.current.click();
            return;
        }

        const formData = new FormData();
        formData.append('file', file); // appending file
        const onUploadProgress = (ProgressEvent) => {
            let progress = Math.round(
                ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
            setProgess(progress);
        }
        setLoading(true);
        return uploadFunc(formData, file.name, onUploadProgress).then(
            (response) => {
                setFile("");
            },
            (error) => {
                setMessage(Helper.errorToString(error));
                setFile("");
            }
        ).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className="file-upload">
            <input type="file" ref={el} onChange={handleChange} className="d-none" />
            {/* <div className="progessBar" style={{ width: progress }}>
                    {progress}
                </div> */}
            <div className="d-flex align-items-center">
                <button onClick={uploadFile} className="btn btn-success me-2" disabled={loading}>
                    {file ? "Начать загрузку" : "Выбрать файл"} {loading ? <em className="fa fa-spin fa-gear"></em> : ""}
                </button>
                {file ? file.name : ""}
            </div>
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
        </div>
    );
};

export default FileUploader;


