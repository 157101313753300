import React, { useState, useEffect } from 'react';
import MainTitle from '../common/MainTitle';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import StopWordsService from './StopWordsService';
import Toasts from '../common/Toasts';

import Strings from '../common/Strings';

const StopWords = () => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [stopWords, setStopWords] = useState('');



    const validationSchema = yup.object().shape({
        text: yup.string()
    });

    const { register, handleSubmit, setValue, reset, watch, control, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const getStopWords = () => {
        StopWordsService.getAntimatText().then((response) => {
            setStopWords(response);
            setValue("antimat_text", response);
        });
    }

    const handleUpdate = (formData) => {
        setMessage("");
        setSuccessMessage("");
        
        StopWordsService.updateAntimatText(formData).then((response) => {
            setSuccessMessage("Данные успешно обновлены");
            setInterval(() => {
                setSuccessMessage("");
            }, 5000);
        },
        (error) => {
            console.log(error);
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();


            setMessage(Strings.errors[resMessage] ? Strings.errors[resMessage] : resMessage);
        });
    }

    useEffect(() => {
        getStopWords();
    }, []);

    return (<div>
        <div className='container-fluid'>
            <MainTitle title="Стоп-слова" />

            <section className='p-4'>
                <form onSubmit={handleSubmit(handleUpdate)} autoComplete="off">
                    <div className="form-group">
                        <label>Список стоп-слов (каждое с новой строчки)</label>
                        <textarea rows="10" role="presentation"
                            {...register('antimat_text')}
                            className={`form-control ${errors.antimat_text ? 'is-invalid' : ''}`}
                        ></textarea>
                        <div className="form-error">{errors.antimat_text?.message}</div>
                    </div>
                    <div className="mt-2 d-flex align-items-center justify-content-between">
                        <button type="submit" className="btn btn-primary">
                            Обновить
                        </button>
                    </div>
                </form>
                {message && (<div className="alert alert-danger mt-4">
                    {message}
                </div>)}
                {successMessage && (<div className="alert alert-success mt-4">
                    {successMessage}
                </div>)}
            </section>
            <Toasts message={message} setMessage={setMessage} />
        </div>
    </div>)
}

export default StopWords