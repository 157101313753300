import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import Helper from '../common/Helper';
import CommentsService from './CommentsService';

const CommentEditor = ({ data, onUpdated, publicationId }) => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        comment_text: yup.string()
            .required('Поле обязательно для заполнения'),

    });

    const { register, handleSubmit, setValue, reset, watch, control, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: data && {
            comment_text: data.comment_text
        }
    });
    const handleUpdate = (formData) => {
        setMessage("");
        setSuccessMessage("");
        formData["publication_id"] = publicationId;
        const func = data ? CommentsService.update : CommentsService.create;
        if (data) {
            formData["id"] = data.id;
        }
        func(formData).then((responce) => {

            onUpdated();
            reset();
            // if (!data) {
            //     reset();
            //     navigate("/categories/");
            // }

        },
            (error) => {
                setMessage(Helper.errorToString(error));
            });
    };
    useEffect(() => {
        if (data) {
            setValue("comment_text", data.comment_text);
        }
    }, [data]);

    const onKeyDown = (e) => {
        if ((e.keyCode == 10 || e.keyCode == 13) && e.ctrlKey) {
            //document.getElementById("commentForm").submit();
            handleSubmit(handleUpdate)();
        }
    }

    return (<div>
        <form onSubmit={handleSubmit(handleUpdate)} autoComplete="off" id="commentForm">
            <div className="form-group">
                <textarea role="presentation" onKeyDown={onKeyDown}
                    name="comment_text"
                    {...register('comment_text')}
                    className={`form-control ${errors.comment_text ? 'is-invalid' : ''}`}
                />
                <div className="form-error">{errors.comment_text?.message}</div>
            </div>
            <div className="mt-2 d-flex align-items-center justify-content-between">
                <button type="submit" className="btn btn-primary">
                    {data ? "Обновить" : "Создать"}
                </button>
            </div>
        </form>
        {message && (<div className="alert alert-danger mt-4">
            {message}
        </div>)}
    </div>)
}

export default CommentEditor