import axios from 'axios';
import AuthService from '../auth/AuthService';
import originHeaders from '../auth/AuthService';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const get = (page, page_size) => {
    return axios.get(API_URL + 'pages?page=' + page + "&page_size=" + page_size, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

const getOne = (id) => {
    return axios.get(API_URL + 'pages/' + id, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

const update = (data) => {
    return axios.post(API_URL + 'pages/' + data.id, data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const create = (data) => {
    return axios.post(API_URL + 'pages', data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const del = (id) => {
    return axios.delete(API_URL + 'pages/' + id,
        AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const InfopagessService = {
    get,
    getOne,
    update,
    del,
    create
}

export default InfopagessService;