import axios from 'axios';
import AuthService from '../auth/AuthService';
import originHeaders from '../auth/AuthService';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const get = () => {
    return axios.get(API_URL + 'transactions', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

const getForUser = (user_id) => {
    return axios.get(API_URL + 'transactions/' + user_id, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

// const update = (data) => {
//     return axios.post(API_URL + 'transactions', data, AuthService.getTokenHeaders())
//         .then((response) => {
//             return response.data;
//         });
// };

const create = (data) => {
    return axios.post(API_URL + 'transactions/', data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const del = (id) => {
    return axios.delete(API_URL + 'transactions/' + id,
        AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const TransactionsService = {
    get,
    getForUser,
    create,
    del
}

export default TransactionsService;