import axios from 'axios';
import AuthService from '../auth/AuthService';
import originHeaders from '../auth/AuthService';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const get = () => {
    return axios.get(API_URL + 'settings', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

// const getOne = (id) => {
//     return axios.get(API_URL + 'someurl/'+id, AuthService.getTokenHeaders())
//         .then(function (response) {
//             return response.data;
//         })
// }

const update = (data) => {
    return axios.post(API_URL + 'settings', data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

// const del = (id) => {
//     return axios.delete(API_URL + 'someurl/' + id,
//         AuthService.getTokenHeaders())
//         .then((response) => {
//             return response.data;
//         });
// }

const SettingsService = {
    get,
//    getOne,
    update,
//    del
}

export default SettingsService;